.page-client {
  .bg-page-title {
    background: linear-gradient(-90deg, rgba(42, 82, 153, 1) 0%, rgba(21, 41, 77, 1) 100%);
    background-position: center center;
    background-size: cover;

    @media (max-width: 600px) {
      margin-top: pxToVW(60);
    }

    img {
      width: 100%;
    }
  }

  .container {
    padding-top: pxToRem(50);
    padding-bottom: pxToRem(50);
  }

  .page-title {
    height: pxToRem(50);
    @include bgMain();
    text-align: center;
    border-radius: 5px;
    margin-bottom: pxToRem(50);
    padding: pxToRem(10);
    font-size: pxToRem(30);
    font-weight: bold;
    line-height: 1.4;
    color: $white;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-data {
    text-align: center;
    font-size: pxToRem(30);
    color: $color-light1;
  }
}

.wrap-slide {

  .item-client {
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 pxToRem(3) pxToRem(10) 0 rgba(0, 0, 0, 0.16);
    @include transition(0.3s all);
    border: pxToRem(4) solid transparent;
    margin-bottom: pxToRem(34);

    &:hover {
      border-color: $bg_main;
    }

    .item-image {
      width: pxToRem(250);
      min-height: pxToRem(200);
      background-color: $btn-color;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;
    }

    .item-content {
      margin-left: 10px;
      width: calc(100% - #{pxToRem(375)});

      .title {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.23;
        margin-bottom: pxToRem(10);
        color: $bg_main;
        text-transform: uppercase;
        cursor: pointer;
      }

      .date {
        font-size: 13px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: italic;
        line-height: 1.22;
        letter-spacing: pxToRem(0.36);
        color: $color_black
      }

      .desc {
        margin-top: pxToRem(20);
        font-size: 15px;
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: italic;
        line-height: 1.5;
      }

      label {
        font-size: pxToRem(25);
        font-weight: 500;
        color: $color_black;
        line-height: 1.2;
        letter-spacing: pxToRem(0.5);
        margin-bottom: pxToRem(13);
      }

      .company-name {
        margin-bottom: pxToRem(30);
      }

      .location,
      .information {
        margin-bottom: pxToRem(30);

        .desc {
          margin-top: 0;
          font-size: pxToRem(25);
          font-weight: 500;
          font-style: normal;
          line-height: 1.24;
          color: #b40706;
        }
      }

      .information {
        li {
          height: pxToRem(34);
          padding: pxToRem(6) pxToRem(10);
          background-color: $bg-light11;
          font-size: pxToRem(17);
          font-weight: bold;
          color: $white;
          line-height: 1.3;
          border-radius: pxToRem(20);
          margin-right: pxToRem(10);
          display: inline-block;
          margin-bottom: pxToRem(5);
        }
      }
    }

    .row-right {
      display: inline-block;
      text-align: right;
      float: right;
      margin-top: pxToRem(30);

      img {
        max-width: pxToRem(30);
      }
    }
  }
}

.wrapper-pagination {

  .pagination {
    padding: 0;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 35px;
    cursor: pointer;

    .page-item {
      height: pxToRem(32);
      min-height: 32px;
      min-width: 32px;
      font-size: pxToRem(15);
      line-height: 2;
      text-align: center;
      font-weight: 500;
      color: $color-light1;
      border: solid pxToRem(1) $bg-light5;
      box-shadow: pxToRem(6) pxToRem(6) pxToRem(6) 0 rgba(0, 0, 0, 0.23);
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: pxToRem(6);
        border-bottom-left-radius: pxToRem(6);
      }

      &:last-child {
        border-top-right-radius: pxToRem(6);
        border-bottom-right-radius: pxToRem(6);

        a {
          color: $bg_main;
        }
      }

      &.active {
        a {
          background-color: $bg_main;
          color: $white;
        }
      }

      a {
        outline: none;
        padding: 0;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: pxToRem(15);
        text-align: center;
        color: $color-light1;
        padding: pxToRem(7) pxToRem(9);
        border: 0;

        &:focus {
          border: 0;
          box-shadow: none;
        }

        &:hover {
          background-color: $bg_main;
          color: $white;
        }
      }
    }
  }
}

.custom-container {
  padding-left: pxToRem(128);
  padding-right: pxToRem(128);
  margin: auto;
}

.detail {
  .bg-page-title {
    position: relative;

  }

  .detail-top {
    width: 100%;
    padding: pxToRem(25) pxToRem(128) pxToRem(25) pxToRem(128);
    @include bgMain();

    .detail-title {
      font-size: pxToRem(25);
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
      max-width: pxToRem(1170);
      color: $white;
    }

    ul {
      display: flex;
      margin-top: pxToRem(10);
      align-items: center;

      li {
        font-size: pxToRem(20);
        font-weight: normal;
        line-height: 1.2;
        text-align: left;
        color: $white;
        @include transition(0.3s all);
        white-space: nowrap;

        &.link {
          &:hover {
            color: $bg_main;
          }
        }

        &.sub-title {
          text-transform: lowercase;
          max-width: pxToRem(450);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .line {
        padding: 0 pxToRem(8);
      }
    }
  }

  .content-detail {
    padding: pxToRem(50) 0;

    p {
      font-size: pxToRem(14);
      font-weight: normal;
      font-style: normal;
      line-height: 1.5;
      color: $color_black;
      margin-bottom: pxToRem(20);
      font-family: 'Montserrat';
    }

    strong {
      font-size: 20px;
      font-family: 'Bebas';
      @media (max-width: 600px) {
        font-size: 17px;
      }
    }
   

    img {
      margin: auto;
    }

    // span {
    //   font-size: pxToRem(15);
    //   display: block;
    //   font-style: italic;
    //   line-height: 2;
    //   margin-bottom: pxToRem(25);
    //   text-align: center;
    // }
  }

  img {
    width: 100%;
  }

  .recent-post {
    margin-top: pxToRem(70);

    h3 {
      font-size: pxToRem(30);
      font-weight: bold;
      line-height: 1.2;
      display: inline-block;
      color: $bg_main;
      padding-bottom: pxToRem(10);
      border-bottom: pxToRem(5) solid $bg-main;
      margin-bottom: pxToRem(25);
    }

    ul {
      li {
        font-size: pxToRem(20);
        font-weight: 500;
        line-height: 1.3;
        color: $color_black;
        margin-bottom: pxToRem(8);
        text-transform: uppercase;

        &:hover {
          color: $bg-main;
        }
      }
    }
  }

  .list-category {
    border-radius: pxToRem(10);
    box-shadow: 0 pxToRem(3) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
    border: solid pxToRem(1) $color-border;
    background-color: $bg-footer;
    margin-left: pxToRem(50);

    h3 {
      padding: pxToRem(15) pxToRem(44);
      @include bgMain();
      color: $white;
      text-transform: uppercase;
      font-size: pxToRem(25);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      border-top-left-radius: pxToRem(11);
      border-top-right-radius: pxToRem(11);
    }

    ul {
      padding: pxToRem(15) pxToRem(43);

      li {
        padding: pxToRem(15) 0;
        font-size: pxToRem(20);
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.2;

        a {
          color: $bg-main;
          display: block;

          &:hover {
            color: $color_black;
          }
        }
      }
    }
  }
}


@include breakpoint(sm992) {

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .page-client {
    .container {
      padding-top: pxToVW(20);
      padding-bottom: pxToVW(20);
    }

    .page-title {
      height: pxToVW(40);
      border-radius: 3px;
      margin-bottom: pxToVW(21);
      padding: pxToVW(6);
      font-size: pxToVW(20);
    }

    .no-data {
      font-size: pxToVW(20);
      width: 100%;
      text-align: center;
    }
  }



  .wrap-slide {
    .company-info {
      .item-client {
        max-height: 100%;
      }
    }

    .list-client {

      display: flex;
      flex-wrap: wrap;
      margin-right: pxToVW(-8);
      margin-left: pxToVW(-8);

    }

    .item-client {
      display: block;
      width: 47%;
      max-height: pxToVW(270);
      border-radius: pxToVW(10);
      box-shadow: 0 pxToVW(3) pxToVW(10) 0 rgba(0, 0, 0, 0.16);
      border: pxToVW(2) solid transparent;
      margin-bottom: pxToVW(34);
      flex: 0 0 47%;
      max-width: 47%;
      margin: pxToVW(5.5);

      .item-image {
        width: 100%;
        min-height: pxToVW(152);
        border-top-left-radius: pxToVW(10);
        border-bottom-left-radius: 0;
        border-top-right-radius: pxToVW(10);
      }

      .item-content {
        padding: pxToVW(6) pxToVW(10);
        width: 100%;

        .title {
          font-size: pxToVW(10);
          margin-bottom: pxToVW(8);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          max-height: pxToVW(23);
          cursor: pointer;
        }

        .date {
          font-size: pxToVW(8);
          letter-spacing: pxToVW(0.36);
        }

        .desc {
          margin-top: pxToVW(8);
          font-size: pxToVW(10);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          max-height: pxToVW(30);
        }

        label {
          font-size: pxToVW(15);
          letter-spacing: pxToVW(0.5);
          margin-bottom: pxToVW(13);
        }

        .company-name {
          margin-bottom: pxToVW(15);
        }

        .location,
        .information {
          margin-bottom: pxToVW(15);

          .desc {
            font-size: pxToVW(10);
          }
        }

        .information {
          li {
            height: pxToVW(34);
            padding: pxToVW(6) pxToVW(10);
            font-size: pxToVW(13);
            border-radius: pxToVW(10);
            margin-right: pxToVW(10);
          }
        }
      }

      .row-right {
        float: none;
        margin-top: pxToVW(10);
        display: flex;
        justify-content: flex-end;

        img {
          max-width: pxToVW(18);
          height: pxToVW(10);
          width: pxToVW(18);
        }
      }
    }
  }

  .wrapper-pagination {

    .pagination {
      margin-top: pxToVW(50);

      .page-item {
        height: pxToVW(32);
        min-height: 26px;
        min-width: 32px;
        font-size: pxToVW(15);
        border: solid pxToVW(1) $bg-main;

        &:first-child {
          border-top-left-radius: pxToVW(6);
          border-bottom-left-radius: pxToVW(6);
        }

        &:last-child {
          border-top-right-radius: pxToVW(6);
          border-bottom-right-radius: pxToVW(6);
        }

        a {
          font-size: pxToVW(15);
          padding: pxToVW(5) pxToVW(9);
        }
      }
    }
  }

  .custom-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .detail {
    .detail-top {
      padding: pxToVW(13) pxToVW(23);
      position: relative;
      margin-top: -3px;

      .detail-title {
        font-size: 16px;
        max-width: pxToVW(350);
      }

      ul {
        margin-top: pxToVW(10);

        li {
          font-size: 15px;

          &.sub-title {
            max-width: 100%;
          }
        }

        .line {
          padding: 0 pxToVW(3);
        }
      }
    }

    .content-detail {
      padding: pxToVW(20) 0;

      p {
        font-size: 16px;
        margin-bottom: pxToVW(10);
      }

      span {
        font-size: 16px;
        margin-bottom: pxToVW(10);
        line-height: 1.4;
      }
    }

    .recent-post {
      margin-top: pxToVW(40);

      h3 {
        font-size: pxToVW(18);
        padding-bottom: pxToVW(10);
        border-bottom: pxToVW(2) solid $bg-main;
        margin-bottom: pxToVW(15);
      }

      ul {
        li {
          font-size: 14px;
          margin-bottom: pxToVW(8);
        }
      }
    }

    .list-category {
      border-radius: pxToVW(6);
      border: solid pxToVW(1) $bg-main;
      margin-left: 0;
      margin-top: pxToVW(50);

      h3 {
        padding: pxToVW(10) pxToVW(15);
        font-size: pxToVW(18);
        border-top-left-radius: pxToVW(6);
        border-top-right-radius: pxToVW(6);
      }

      ul {
        padding: pxToVW(10) pxToVW(15);

        li {
          padding: pxToVW(10) 0;
          font-size: pxToVW(15);
        }
      }
    }
  }
}

.page-client {
  min-height: 50vh;
}

.detail {
  .list-category {

    .dich-vu,
    .hop-tac,
    .gioi-thieu,
    .lien-he,
    .cau-hoi-thuong-gap,
    .chinh-sach-bao-mat,
    .dieu-khoan-su-dung {
      display: none !important;
    }
  }

  &.chinh-sach-bao-mat,
  &.lien-he,
  &.dich-vu,
  &.hop-tac,
  &.gioi-thieu,
  &.dieu-khoan-su-dung,
  &.cau-hoi-thuong-gap {

    .bg-page-title,
    .detail-top,
    .recent-post {
      // display: none !important;
    }



    .custom-container {
      padding-left: pxToRem(70);
      padding-right: pxToRem(70);
      margin: auto;

      .content-detail {
        .row-left {
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
        }

        .row-right {
          display: none !important;
        }
      }

      @include breakpoint(sm992) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}