.ckeditor-content {
  color: #333;
  line-height: 1.6;
  padding: 1rem;

  p {
    font-size: 14px;
    // text-transform: capitalize;
    font-family: 'Montserrat';
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5em 0 0.5em;
    // font-weight: bold;
    line-height: 1.2;
    font-size: 20px;
    font-family: 'Bebas';
  }

  a {
    color: #1a0dab;
    text-decoration: underline;
    &:hover {
      color: #d93025;
    }
  }

  ul,
  ol {
    margin: 1em 0;
    padding-left: 1.5em;
  }

  li {
    margin-bottom: 0.5em;
  }

  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1em;
    color: #666;
    font-style: italic;
    margin: 1em 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 15px;
      border: 1px solid #ddd;
      text-align: left;
      font-family: "Montserrat";
      font-weight: 400;
    }

    tr {
      border: 1px solid #fff;
      padding: 8px 12px;
      cursor: pointer;
      
      th:first-child {
        font-weight: 700;
        width: 30%;
        font-family: 'Bebas';
      }
    }

    tr:nth-child(even) {
      background-color: #f5f5f5; 
    }
  }
}
