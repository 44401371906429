.page-partner {
  min-height: 80vh;

  .bg-title-partner {
    height: pxToRem(800);
    padding: pxToRem(200) pxToRem(90) pxToRem(60) pxToRem(90);
    background-color: rgba($bg-black2, 0.4);
    background-position: center center;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      opacity: 0.49;
      top: 0;
      background-color: #000000;
      width: 100%;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: 0;
    }

    .form-search {
      padding-bottom: pxToRem(60);
    }

    .info-partner {
      position: relative;
      z-index: 1;
      text-align: center;
      margin: auto;
      max-width: pxToRem(660);

      .logo-partner {
        width: pxToRem(270);
        height: pxToRem(270);
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        margin: auto;
      }
    }

    h3 {
      font-size: pxToRem(50);
      margin-top: pxToRem(15);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      color: $white;
    }

    .rating {
      margin: pxToRem(10) 0;

      svg {
        margin: pxToRem(6);
        width: pxToRem(25)
      }
    }

    .form-search.form2 {
      position: relative;
      z-index: 2;

      .form-group .basic-multi-select {
        .select__value-container {
          background-color: rgba($bg-light9, 0.6);
        }
      }
    }
  }

  .company-info {
    .item-client {
      max-width: pxToRem(1170);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      display: block;

      .item-content {
        width: 100%;
        display: flex;
        align-items: flex-start;
      }

      .group-left {
        width: 50%;
      }

      .information {
        width: 50%;

        .hashtag-size {
          margin-top: 1.5625vw;
        }
      }
    }

    .wrap-item-client {
      margin-bottom: pxToRem(20);

      .promotion {
        padding: pxToRem(25) pxToRem(20);
        text-align: center;
        font-size: pxToRem(20);
        color: $white;
        margin-left: auto;
        margin-right: auto;
        border-bottom-right-radius: pxToRem(20);
        border-bottom-left-radius: pxToRem(20);
        background: linear-gradient(-90deg, rgba(42, 82, 153, 1) 0%, rgba(21, 41, 77, 1) 100%);
        width: calc(100% + 6px);
        left: -3px;
        line-height: 1.4;
        position: relative;
        bottom: -4px;
      }
    }
  }
}

.content-company {
  padding: pxToRem(45) pxToRem(245) pxToRem(130) pxToRem(245);
  background-color: $bg-light10;

  .company-info {
    margin-bottom: pxToRem(90);

  }
}

.title-page {
  font-size: pxToRem(50);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: $bg-main;
  margin-bottom: pxToRem(35);
  padding-bottom: pxToRem(20);
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    border-bottom: solid pxToRem(5) $bg-main;
    position: absolute;
    width: pxToRem(250);
    left: 50%;
    bottom: 0;
    @include transform-translateX(-50%)
  }
}

.product-company {
  margin-bottom: pxToRem(80);

}

.product-list-company {
  position: relative;

  @media (min-width: 992px) {

    .swiper-button-next,
    .swiper-button-next2 {
      right: pxToRem(-60);
    }

    .swiper-button-prev,
    .swiper-button-prev2 {
      left: pxToRem(-60);
    }
  }


  .swiper-container {
    padding: 0 0 pxToRem(30) 0;
  }

  .company-item {
    min-height: pxToRem(400);
    border-radius: pxToRem(20);
    box-shadow: 0 pxToRem(3) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
    padding-bottom: pxToRem(40);
    text-align: center;
    @include transition(0.3s all);
    border: pxToRem(4) solid transparent;
    position: relative;

    &:hover,
    &:active,
    &:focus {
      border: pxToRem(4) solid $bg-main;
    }

    .bg-image {
      background-color: $bg-light;
      border-radius: pxToRem(20);
      height: 100%;
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      cursor: pointer;
    }

    .title {
      cursor: pointer;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: pxToRem(25) pxToRem(15);
      background-color: rgba($bg-main, 0.8);
      border-bottom-left-radius: pxToRem(20);
      border-bottom-right-radius: pxToRem(20);
    }

    h3 {
      font-size: pxToRem(20);
      font-weight: bold;
      line-height: 1.2;
      color: $white;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: pxToRem(70);
    }
  }
}

.product-list-rating {
  background-color: $white;
  padding: pxToRem(10) pxToRem(40);
  padding-bottom: pxToRem(30);
  border-radius: pxToRem(25);
  box-shadow: pxToRem(5) pxToRem(4) pxToRem(8) #8c8d8d;

  img {
    width: 100%;
  }

  .comment-item {
    border-bottom: solid pxToRem(0.5) rgba(#1a1818, 0.3);
    padding: pxToRem(30) pxToRem(20) pxToRem(30) pxToRem(0);

    .name,
    .date,
    .desc {
      font-size: pxToRem(20);
      font-weight: 300;
      line-height: 2;
      color: $bg-black3;
    }

    .desc {
      font-size: pxToRem(22);
    }

    .logo {
      margin-right: pxToRem(25);

      img {
        background-color: $bg-light11;
        width: pxToRem(85);
        height: pxToRem(85);
        border-radius: 50%;
      }
    }
  }
}

.quotation {
  position: fixed;
  top: 50%;
  right: 0;
  width: pxToRem(289);
  border-top-left-radius: pxToRem(20);
  padding: pxToRem(20);
  border-bottom-left-radius: pxToRem(20);
  height: pxToRem(85);
  box-shadow: 0 pxToRem(3) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to left, rgba(153, 42, 42, 0.9) 19%, rgba(255, 201, 93, 0.9) 105%);
  cursor: pointer;
  color: $white;
  font-size: pxToRem(40);
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: pxToRem(0.8);
  text-align: center;
  z-index: 99;
}

.quotation-chose {
  height: 100%;
  overflow-y: auto;
  max-height: pxToRem(600);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 pxToRem(1) rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: pxToRem(1);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: pxToRem(1) solid transparent;
  }

  li {
    padding: pxToRem(19) pxToRem(54);
    border-radius: pxToRem(3);
    border: solid pxToRem(1) $color-border;
    margin-bottom: pxToRem(10);
    cursor: pointer;
    @include transition(0.3s all);

    &.disable-row {
      pointer-events: none !important;
      opacity: 0.6;
    }

    &:hover {
      border-color: $bg-main;
    }
  }

  .checkbox {
    z-index: -1;
  }
}

.content-quotation {
  max-height: pxToRem(600);
  min-height: pxToRem(200);
  overflow: hidden;
}

@include breakpoint(sm992) {
  .page-partner {
    .bg-title-partner {
      height: pxToVW(350);
      min-height: 300px;
      padding: pxToVW(90) pxToVW(20) pxToVW(30) pxToVW(20);

      .form-search {
        padding-bottom: pxToVW(30);
      }

      .info-partner {
        max-width: pxToVW(460);

        .logo-partner {
          width: pxToVW(82);
          height: pxToVW(82);
        }
      }

      h3 {
        font-size: pxToVW(18);
        margin-top: pxToVW(8);
      }

      .rating {
        margin: pxToVW(5) 0;

        svg {
          margin: pxToVW(8);
          width: pxToVW(20);
          margin: pxToVW(3);
        }
      }
    }

    &.wrap-slide {
      .item-client {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        height: auto;
        margin: 0;
        padding-bottom: pxToVW(30);

        .company-name {

          .title {
            font-size: pxToVW(24);
            height: auto;
            max-height: 100%;
            overflow: unset;
          }
        }

        .desc {
          font-size: pxToVW(15) !important;
          height: 100%;
          max-height: 100%;
        }

        .information {
          display: flex;
          flex-wrap: wrap;

          >div {
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
          }

          .hashtag-size {
            margin-top: pxToVW(25);
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;

            ul {
              display: block !important;
            }

            li {
              height: pxToVW(22);
              padding: pxToVW(5) pxToVW(8);
              font-size: pxToVW(9);
              border-radius: pxToVW(15);
              margin-bottom: pxToRem(10);
              display: inline-block;
            }
          }
        }
      }
    }

    .company-info {
      .item-client {
        max-width: pxToVW(1170);

        .item-content {
          width: 100%;
          display: block;
          padding: pxToVW(25);
          padding-bottom: 0;
        }

        .group-left {
          width: 100%;

          .location {
            display: block;
            float: right;
            width: 27%;

            &.mb-0 {
              width: 73%;
              display: block;
              float: left;
            }
          }
        }

        .information {
          width: 100%;

          .justify-content-between {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }

          .hashtag-size,
          .personnel-size,
          .tax-code {
            margin-top: pxToVW(20);
          }
        }
      }
    }
  }

  .content-company {
    padding: pxToVW(25) pxToVW(23) pxToVW(0) pxToVW(23);

    .company-info {
      margin-bottom: pxToVW(40);

      .wrap-item-client {
        margin-bottom: pxToRem(20);

        .item-client {
          padding-bottom: 0;

          .item-content {
            padding-bottom: pxToVW(30);
          }
        }

        .promotion {
          padding: pxToVW(20) pxToVW(20);
          font-size: pxToVW(15);
          border-bottom-right-radius: pxToVW(10);
          border-bottom-left-radius: pxToVW(10);
          width: calc(100% + 4px);
          left: -2px;
          bottom: -3px;
        }
      }
    }

    .product-company:last-child {
      margin-bottom: 0;
    }
  }

  .title-page {
    font-size: pxToVW(20);
    margin-bottom: pxToVW(20);
    padding-bottom: pxToVW(10);

    &::after {
      border-bottom: solid pxToVW(2) $bg-main;
      width: pxToVW(140);
    }
  }

  .product-company {
    margin-bottom: pxToVW(30);
  }

  .product-list-company {

    overflow: hidden;

    &.mobile {
      width: calc(100% + #{pxToVW(28)});
      left: pxToVW(-14);
      position: relative;
    }

    .swiper-container {
      padding: 0 0 pxToVW(20) 0;
    }

    .company-item {
      min-height: pxToVW(210);
      border-radius: pxToVW(10);
      box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
      padding-bottom: pxToVW(20);
      border: pxToVW(2) solid transparent;

      &:hover,
      &:active,
      &:focus {
        border: pxToVW(2) solid $bg-main;
      }

      .bg-image {
        border-radius: pxToVW(10);
      }

      .title {
        padding: pxToVW(10) pxToVW(5);
        border-bottom-left-radius: pxToVW(10);
        border-bottom-right-radius: pxToVW(10);
      }

      h3 {
        font-size: pxToVW(12);
        text-align: center;
        max-height: pxToVW(33);
      }
    }
  }

  .product-list-rating {
    padding: 0 pxToVW(15) 0 PxToVW(15);
    background-color: transparent;
    box-shadow: pxToVW(5) pxToVW(4) pxToVW(8) #8c8d8d;
    margin-bottom: pxToVW(20);

    .comment-item {
      border-bottom: solid pxToVW(1) rgba($bg-black3, 0.4);
      padding: pxToVW(15) 0 pxToVW(15) pxToVW(0);



      .name,
      .date,
      .desc {
        font-size: pxToVW(12);
      }

      .desc {
        font-size: pxToVW(14);
        line-height: 1.4;
      }

      .logo {
        margin-right: pxToVW(10);

        img {
          width: pxToVW(45);
          height: pxToVW(45);
        }
      }
    }
  }

  .quotation {
    width: pxToVW(130);
    border-top-left-radius: pxToVW(10);
    padding: pxToVW(10);
    border-bottom-left-radius: pxToVW(10);
    height: pxToVW(60);
    box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
    font-size: pxToVW(20);
    letter-spacing: pxToVW(0.8);
    line-height: 2;
  }

  .quotation-chose {
    min-height: pxToVW(250);

    &::-webkit-scrollbar {
      width: pxToVW(1);
    }

    &::-webkit-scrollbar-thumb {
      border: pxToVW(1) solid transparent;
    }

    li {
      padding: pxToVW(10) pxToVW(30);
      border-radius: pxToVW(3);
      border: solid pxToVW(1) $color-border;
      margin-bottom: pxToVW(10);
    }
  }

  .content-quotation {
    max-height: pxToVW(600);
  }

  .swiper-button-prev {
    left: 3px;
  }

  .swiper-button-next {
    right: 3px;
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev2,
  .swiper-button-next2 {
    &::after {
      font-size: pxToVW(30);
      color: #b40706;
    }
  }
}

.more-btn {
  cursor: pointer;
  color: #b40706;
  text-decoration: underline;
  font-weight: 300;
}