.warranty {
  position: relative;
  &__banner {
    background-image: url('../../../assets/imgs/warranty-banner.jpg');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // margin-top: 50px;
    @media (max-width: 600px) {
      background-size: contain;
      height: 35vh;
      margin-top: 0;
      position: unset;
    }
  }

  &__form {
    width: 40%;
    position: absolute;
    top: 30%;
    left: 10%;
    @media (max-width: 600px) {
      position: unset;
      width: 100%;
      margin-top: -50px;
    }

    .card {
      border: none;
      background-color: transparent;
      border-radius: 0;
      cursor: pointer;
    }

    .card-header {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-top: 10px !important;
      border: 1px solid rgba(0,0,0,.125);
      .chevron-down {
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-top: 5px;
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
      }
      .rotate {
        transform: rotate(180deg);
      }
      .btn-link {
        color: #b40706;
        font-family: 'Bebas';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        text-decoration: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .card-body {
      padding: 0;
    }
  }
}
