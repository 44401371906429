.session-slide {
  z-index: 9;
}

.session-slide,
.session-video,
.bg-image-main {
  height: pxToRem(800);
  background-position: center center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;

  &::before {
    content: "";
    opacity: 0.49;
    top: 0;
    background-color: $color-black;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 0;
  }

  .slide-info {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    text-align: center;
    z-index: 1;
    @include transform-translate(-50%, -50%);

    .title-slide {
      margin-bottom: pxToRem(45);
      font-size: pxToRem(50);
      font-weight: 500;
      line-height: 1.22;
      text-align: center;
      color: $white;
    }

    .form-search {
      position: relative;
      z-index: 1;
    }

    .btn-wrap {
      z-index: 0;
    }
  }
}

.banner {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center center;

  @include breakpoint(lgmax) {
    height: 900px;
  }

  @include breakpoint(lgmax18) {
    height: 750px;
  }

  @include breakpoint(lgmax17) {
    height: 680px;
  }

  @include breakpoint(lgmax16) {
    height: 650px;
  }

  @include breakpoint(mimax992) {
    height: 550px;
  }

  @media (max-width: 600px) {
    height: 400px;
  }
}

.session-video {
  position: relative;

  .session-bg-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  &:hover .btn-video {

    display: block !important;

  }

  .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform-translate(-50%, -50%);
    cursor: pointer;
    z-index: 99;
  }

  .bg-session-video {
    position: relative;
    height: 100%;
    background-position: center center;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      opacity: 0.49;
      top: 0;
      background-color: $color-black;
      width: 100%;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: 0;
    }
  }

  .video-info {
    padding: pxToRem(160) pxToRem(15);
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    right: 0;
    height: 60%;
    color: $white;
    text-align: center;
    margin: 0 auto;
    z-index: 99;

    &::before {
      content: "";
      top: 0;
      left: 0;
      z-index: 991;
      width: 100%;
      height: 70%;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      box-shadow: 0 50px 50px rgba(0, 0, 0, 0.4);
    }

    .wrap-gallery-video {
      max-width: pxToRem(1200);
      text-align: center;
      margin: 0 auto;
      position: relative;
      z-index: 999;
    }

    h3 {
      font-size: pxToRem(40);
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: pxToRem(20);
      text-transform: uppercase;
      text-align: center;
    }

    .desc {
      font-size: pxToRem(30);
      line-height: 1.33;
      letter-spacing: pxToRem(0.3);
      text-align: center;
    }
  }
}

.title-slide {
  font-family: 'Bebas';
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.session-promotions,
.session-product {
  padding: 33px 99px;
  background-color: #b40706;

  @include breakpoint(lg1280) {
    padding: 33px 120px;
  }

  @include breakpoint(xl500) {
    padding: 33px 162px;
  }

  .more-product {
    width: 100%;
    text-align: center;
    margin-top: 17px;

    a {
      font-family: 'Bebas';
      font-size: 17.5pxFv;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.26;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      text-decoration: underline;
    }
  }

  .slide-promotions {
    position: relative;

    .swiper-wrapper {

      .slide-content {
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        padding-bottom: pxToRem(40);
        text-align: center;
        @include transition(0.3s all);
        position: relative;
        background-color: #fff;
        min-height: 260px;
        border: solid 0.3px #b6b6b6;

        @include breakpoint(lg1280) {
          max-width: 220px;
        }

        @include breakpoint(xl500) {
          max-width: 220px;
        }

        &:hover,
        &:active,
        &:focus {
          border: pxToRem(4) solid #b40706;
        }

        .bg-image {
          // background-color: $bg-light;
          border-top-right-radius: pxToRem(20);
          border-top-left-radius: pxToRem(20);
          width: 100%;
          background-position: center center;
          background-size: cover;
          margin: 0 auto;

          img {
            border-top-right-radius: pxToRem(20);
            border-top-left-radius: pxToRem(20);
            width: 90%;

            @include breakpoint(sm768) {
              // min-height: 200px;
            }
          }
        }

        a {
          &:hover {
            h3 {
              color: #b40706;
              opacity: 1;
            }
          }
        }

        .wrapper-slide-action {
          display: flex;
          justify-content: space-around;
          align-items: center
        }

        .product-price {
          font-family: 'Bebas';
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.5px;
          text-align: center;
          color: #b40706;
        }

        h3 {
          margin: pxToRem(20) pxToRem(25);
          font-size: 14px;
          line-height: 1.2;
          letter-spacing: pxToRem(0.63);
          opacity: 0.8;
          color: #000;
          text-align: center;
          text-transform: uppercase;
          font-family: 'Montserrat';
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          letter-spacing: pxToRem(-0.4);
          max-height: pxToRem(60);
          font-weight: 600;
          opacity: 0.8;
        }

        .btn-buy {
          box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
          border: solid 0.5px #707070;
          background-color: #fff;
          height: 30px;
          font-size: 13px;
          color: #000;
          object-fit: contain;
          cursor: pointer;
          letter-spacing: 0.31px;
          font-family: 'Bebas';
          border-radius: 0;
        }
      }
    }
  }

  &.consultancy {
    background-color: #fff;

    .slide-promotions {
      .swiper-wrapper {
        .slide-content {
          text-align: left;
          box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
          padding-bottom: pxToRem(30);
          text-align: center;
          @include transition(0.3s all);
          position: relative;
          background-color: #fff;
          // min-height: 260px;
          border: solid 0.3px #b6b6b6;
          max-width: none;


          &:hover,
          &:active,
          &:focus {
            border: pxToRem(4) solid #b40706 !important;
          }

          h3 {
            font-size: pxToRem(14);
            opacity: 1;
            text-align: left;
            letter-spacing: pxToRem(-0.4);
            color: #000;
          }

          .desc {
            margin: pxToRem(20) pxToRem(25);
            font-size: pxToRem(14);
            // font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            color: $color-black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            max-height: pxToRem(70);
            font-family: 'Montserrat';
            text-align: left;
          }

          .bg-image {
            // background-color: $bg-light;
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            width: 100%;
            background-position: center center;
            background-size: cover;
            margin: 0 auto;
            height: pxToVW(60);
            box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.16);

            img {
              border-top-right-radius: pxToRem(20);
              border-top-left-radius: pxToRem(20);
              @include breakpoint(sm768) {
                // min-height: 200px;
              }
            }
          }

          a {
            &:hover {
              h3 {
                color: #b40706;
                opacity: 1;
              }
            }
          }
        }

        .row-right {
          margin: pxToRem(30) pxToRem(25) 0 pxToRem(20);
          text-align: right;
          display: block;

          img {
            width: pxToRem(30);
          }
        }
      }
    }
  }
}

.section-commit {
  background-color: #231f20;
  color: #fff;
  font-family: Roboto;
  padding: 50px 0;
}

.title-pro-kanet1 {
  font-size: 16px;
  color: #fff;
}

.ct-pro-KANET {
  font-size: 13px;
  color: #c8c8c8;
  font-family: 'Montserrat';
}

.session-product {
  background-color: #fff;
}

span[role="button"] {
  cursor: pointer;
}

.session-client {
  .client {
    margin-top: pxToRem(50);

    .swiper-container {
      width: 100%;
    }

    .row .swiper-slide {
      &:nth-child(2n+2) .item-client {
        display: flex;
        flex-direction: column-reverse;

        .item-client-content {
          padding-left: pxToRem(30);
          padding-right: pxToRem(70);
        }

        .item-client-desc {
          text-align: justify;

          &::after {
            left: auto;
            right: 0;
          }
        }

        .item-client-name,
        .item-client-address {
          text-align: right
        }
      }
    }
  }

  .item-client {
    padding: 0;
  }

  .item-client-image {
    height: pxToRem(480);
    background-position: center center;
    background-size: cover;
    background-color: $bg-light;
  }

  .item-client-content {
    padding: pxToRem(30) pxToRem(70);
    padding-right: pxToRem(30);
    @include bgMain();
    color: $white;
    height: pxToRem(480);

    .item-client-desc {
      // font-family: $Lato-Font;
      font-size: pxToRem(32);
      font-weight: 300;
      line-height: pxToRem(50);
      text-align: justify;
      margin-bottom: pxToRem(20);
      position: relative;
      padding-bottom: pxToRem(40);

      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 5;
      // max-height: pxToRem(205);

      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: pxToRem(300);
        bottom: 0;
        border-bottom: pxToRem(2) solid $white;
      }
    }

    .item-client-name {
      font-size: pxToRem(30);
      font-weight: bold;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: pxToRem(0.3);
    }

    .item-client-address {
      font-size: pxToRem(20);
      font-style: italic;
      line-height: 1.5;
      color: $color-light3;
      letter-spacing: pxToRem(0.2);
    }
  }
}

.video-play {
  position: relative;
  z-index: 1;
}

.link-more {
  margin-top: pxToVW(20);
  text-align: center;
  display: none;

  a {
    font-size: pxToVW(16);
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0.1px;
    color: $bg-main;
    text-decoration: underline !important;
  }
}

@include breakpoint(sm992) {
  .link-more {
    display: block;
  }

  .mobile.slide-promotions,
  .product-list-company.mobile {
    overflow: hidden;
    position: relative;

    .swiper-container {
      overflow: visible;
      max-width: pxToVW(140);
      @include transition(0.3s all);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);

      .swiper-wrapper {
        padding-top: pxToVW(30);
        padding-bottom: pxToVW(40);

        .swiper-slide {
          padding-left: 0;
          padding-right: 0;

          &.swiper-slide-next {
            margin-left: pxToVW(-30);
            position: relative;
            opacity: 0.5;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              background-color: transparent;
              z-index: 99;
              height: 100%;
              border-radius: pxToVW(15);
            }
          }

          &.swiper-slide-prev {
            left: pxToVW(14);
            opacity: 0.5;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              background-color: transparent;
              z-index: 99;
              height: 100%;
              border-radius: pxToVW(15);
            }
          }

          &.swiper-slide-active {
            width: pxToVW(177) !important;
            margin-left: pxToVW(-20);
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
            z-index: 99;
            background: $white;
            margin-top: 3px;
          }
        }
      }
    }
  }

  .session-slide,
  .session-video,
  .bg-image-main {
    height: pxToVW(350);

    .slide-info {
      .title-slide {
        max-width: pxToVW(340);
        margin: auto;
        margin-bottom: pxToVW(25);
        font-size: pxToVW(25);
      }
    }
  }

  .session-video {
    min-height: pxToVW(350);
    height: auto;

    iframe {
      max-height: pxToVW(250) !important;
      height: pxToVW(250);
    }


    .icon-play {
      width: pxToVW(50);
      height: pxToVW(60);
      top: 65%;

    }

    .video-info {
      padding: pxToVW(13) pxToVW(20);
      max-width: 100%;
      background-color: $bg-main;
      position: relative;
      height: auto;
      box-shadow: 0 pxToVW(-5) pxToVW(10) 0 rgba(0, 0, 0, 0.36);
      z-index: 99;
      height: 100%;
      display: block !important;

      &::before {
        display: none;
      }

      .wrap-gallery-video {
        max-width: 95%;
      }

      h3 {
        font-size: pxToVW(20);
        margin-bottom: pxToVW(5);
      }

      .desc {
        font-size: pxToVW(12);
        letter-spacing: pxToVW(0.3);
      }
    }
  }

  .session-promotions,
  .session-product {
    padding: pxToVW(35) 0;

    .container-fluid {
      padding-left: pxToVW(10);
      padding-right: pxToVW(10);
    }

    .slide-promotions {
      padding: 0;

      .swiper-wrapper {
        .swiper-slide {
          padding: pxToVW(7);
        }

        .slide-content {
          box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
          padding-bottom: pxToVW(10);
          padding-top: pxToVW(5);
          background: #fff;
          border: solid 0.3px #b6b6b6;


          &:hover,
          &:active,
          &:focus {
            border: pxToVW(2) solid $bg-main;
          }

          .bg-image {
            width: 100%;
            min-height: pxToVW(152);
            border-top-left-radius: pxToVW(10);
            border-bottom-left-radius: 0;
            border-top-right-radius: pxToVW(10);
          }

          h3 {
            font-size: pxToVW(10);
            margin: pxToVW(8) pxToVW(10);
            max-height: pxToVW(23);
          }

          button {
            min-width: pxToVW(65);
            border-radius: pxToVW(5);
            height: pxToVW(20);
            font-size: pxToVW(10);
            text-transform: uppercase;
            font-family: 'Roboto';
          }
        }
      }
    }

    &.consultancy {
      .slide-promotions {
        .swiper-wrapper {
          .slide-content {
            height: pxToVW(250);
            border: solid 0.3px #b6b6b6;
            box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
            padding-top: 0;
            padding-bottom: 20px;

            h3 {
              font-size: 13px;
              margin-bottom: pxToVW(8);
              max-height: pxToVW(23);
            }

            h3 {
              margin: pxToVW(8) pxToVW(10);
              font-size: 13px;
              line-height: 1.2;
              letter-spacing: pxToRem(0.63);
              opacity: 0.8;
              color: $color-black;
              text-align: center;
              text-transform: uppercase;

              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              max-height: pxToRem(60);
              text-align: left;
            }

            .desc {
              margin-top: pxToVW(8);
              font-size: pxToVW(10);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              max-height: pxToVW(50);
            }
          }

          .row-right {
            margin: pxToRem(80) pxToRem(25) 0 pxToRem(20);

            img {
              width: pxToVW(20);
            }
          }
        }
      }
    }
  }

  .session-client {
    .client {
      margin-top: pxToVW(10);
    }

    .item-client {
      height: 100%;
    }

    .item-client-image {
      min-height: pxToVW(150);
      height: 50%;
    }

    .item-client-content {
      padding: pxToVW(15) !important;
      min-height: pxToVW(150);
      height: 50%;

      .item-client-desc {
        font-size: pxToVW(10);
        line-height: 1.4;
        margin-bottom: pxToVW(5);
        padding-bottom: pxToVW(5);
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 6;

        &::after {
          width: pxToVW(60);
          border-bottom: pxToVW(1) solid $white;
        }
      }

      .item-client-name {
        font-size: pxToVW(10);
        letter-spacing: pxToVW(0.3);
      }

      .item-client-address {
        font-size: pxToVW(9);
        letter-spacing: pxToVW(0.2);
      }
    }
  }
}