.user-info {
  min-width: pxToRem(135);
}

.ico-up {
  @include transform-rotate(-90deg);
  position: relative;
  top: pxToRem(4);
  width: pxToRem(11);
  min-width: 9px;
  left: pxToRem(-3);
  cursor: pointer;

  &.style1 {
    top: 0;
    left: pxToRem(10);
  }
}

.ico-down {
  @include transform-rotate(90deg);
  left: 7px;
  position: relative;
}

button.btn-outline {
  outline: none;
  border: 0;
  font-size: pxToRem(17);
  font-weight: 500;
  line-height: pxToRem(24);
  letter-spacing: pxToRem(2);
  color: $text-black;
  background-color: transparent;
  white-space: nowrap;

  &.btn-dk {
    text-transform: uppercase;
  }
}

.from-login {
  position: relative;

  .form-content {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: pxToRem(347);
    // min-height: pxToRem(316);
    padding: pxToRem(25);
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 pxToRem(6) pxToRem(10) 0 rgba(0, 0, 0, 0.16);

    .btn-summit {
      margin-top: pxToRem(30);
    }

    .button {
      height: pxToRem(41);
      width: pxToRem(151);
      min-width: pxToRem(151);
      font-size: pxToRem(15);
      letter-spacing: pxToRem(0.15);
      border-radius: pxToRem(10);
    }
  }
}

.modal-info-user {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  width: pxToRem(210);
  min-height: pxToRem(160);
  padding: pxToRem(25) pxToRem(30) pxToRem(33);
  background-color: $white;
  border-radius: pxToRem(15);
  box-shadow: 0 pxToRem(6) pxToRem(10) 0 rgba(0, 0, 0, 0.16);

  li {
    margin-bottom: pxToRem(10);

    a {
      font-size: pxToRem(20);
      font-weight: 500;
      line-height: 1.5;
      text-align: left;
      color: $color-black;
    }

    button.btn-outline {
      font-size: pxToRem(20);
      font-weight: 500;
      line-height: 1.5;
      padding: 0;
      text-align: left;
      color: $red;
    }
  }
}

.change-pass {
  min-height: 90vh;

  .form-change-pass {
    max-width: 480px;
    margin: auto;
    padding-top: 250px;
    padding-bottom: 50px;
  }

  @include breakpoint(sm992) {
    min-height: 50vh;

    .form-change-pass {
      margin: auto;
      padding-top: 100px;
      padding-bottom: 50px;

      .btn-wrap {
        .button {
          height: pxToVW(35);
          padding: pxToVW(10);
        }
      }
    }
  }
}

small {
  font-size: 14px;
  font-style: italic;
}