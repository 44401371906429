.modal {
  z-index: 9999;

  &.index-9998 {
    z-index: 9998;
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 pxToRem(30);
    margin: 0;
    max-width: pxToRem(780);
  }
}

.modal-backdrop {
  &.show {
    opacity: 0.4;
  }
}

.modal-content {
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  border: none;
  padding: pxToRem(40);
  border-radius: pxToRem(20);
  box-shadow: 0 pxToRem(6) pxToRem(5) 0;

  &__iconClose {
    position: absolute;
    right: pxToRem(-10);
    top: pxToRem(-10);
    width: pxToRem(35);
    height: pxToRem(35);

    &:focus {
      outline: none;
    }

    img {
      width: pxToRem(25);
      height: pxToRem(25);

      &:focus {
        outline: none;
      }
    }
  }

  .modal-footer {
    padding-bottom: 0;
    margin-top: pxToRem(40);
    padding-top: 0;
    border: 0;

    button.button {
      min-width: pxToRem(164);
      border-radius: pxToRem(10);
    }
  }

  .modal-body {
    width: 100%;
    white-space: pre-line;
    padding: 0;
    margin: 0;

    .sub-title {
      font-size: pxToRem(20);
      margin-top: pxToRem(21);
      line-height: 2;
    }

    .text-modal-content {
      font-size: pxToRem(30);
      font-weight: 500;
      line-height: 1.33;
      text-align: center;
      color: $color-black;
      margin-top: pxToRem(30);

      &.rating {
        margin-top: pxToRem(15);

        .mb-30 {
          margin-bottom: pxToRem(30);
        }

        .star-ratings {

          svg {
            width: pxToRem(37) !important;
            height: pxToRem(37) !important;
          }
        }
      }

      .red {
        color: $red;
      }
    }

    .modal-title {
      font-size: pxToRem(40);
      font-weight: bold;
      line-height: 0.75;
      letter-spacing: pxToRem(0.4);
      text-align: center;
      color: $bg-main;
    }

    textarea {
      border-radius: pxToRem(10);
      font-size: pxToRem(20);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: pxToRem(0.4);
      text-align: left;
      color: $color-light4;

      &:hover {
        color: $color-blue-light;
      }

      &::-webkit-input-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(15);
        letter-spacing: pxToRem(-0.25);
        font-style: italic;
      }

      &::placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(15);
        font-style: italic;
        letter-spacing: pxToRem(-0.25);
      }

      &::-moz-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(15);
        font-style: italic;
        letter-spacing: pxToRem(-0.25);
      }

      &:-ms-input-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(15);
        font-style: italic;
        letter-spacing: pxToRem(-0.25);
      }
    }

  }
}

@include breakpoint(sm992) {

  .modal {
    .modal-dialog {
      padding: 0;
      max-width: pxToVW(360);
    }
  }

  .modal-content {
    padding: pxToVW(30);
    border-radius: pxToVW(10);
    box-shadow: 0 pxToVW(6) pxToVW(5) 0;

    &__iconClose {
      right: pxToVW(-15);
      top: pxToVW(-15);
      width: pxToVW(35);
      height: pxToVW(35);

      img {
        width: pxToVW(35);
        height: pxToVW(32);
      }
    }

    .modal-footer {
      margin-top: pxToVW(20);

      button.button {
        min-width: pxToVW(100);
        border-radius: pxToVW(10);
        min-height: pxToVW(31);
        font-size: pxToVW(16);
        line-height: 1.2;
      }
    }

    .modal-body {
      .sub-title {
        font-size: pxToVW(16);
        margin-top: pxToVW(21);
        line-height: 1.4;
      }

      .text-modal-content {
        font-size: pxToVW(16);
        margin-top: pxToVW(16);

        &.rating {
          margin-top: pxToVW(15);

          .mb-30 {
            margin-bottom: pxToVW(16);
          }

          .star-ratings {

            svg {
              width: pxToVW(21) !important;
              height: pxToVW(21) !important;
            }
          }
        }
      }

      .modal-title {
        font-size: pxToVW(25);
        letter-spacing: pxToVW(0.4);
      }

      textarea {
        border-radius: pxToVW(10);
        font-size: pxToVW(16);
        letter-spacing: pxToVW(0.4);

        &::-webkit-input-placeholder {
          font-size: pxToVW(10);
          letter-spacing: pxToVW(-0.25);
        }

        &::placeholder {
          font-size: pxToVW(10);
          letter-spacing: pxToVW(-0.25);
        }

        &::-moz-placeholder {
          font-size: pxToVW(10);
          letter-spacing: pxToVW(-0.25);
        }

        &:-ms-input-placeholder {
          font-size: pxToVW(10);
          letter-spacing: pxToVW(-0.25);
        }
      }
    }
  }
}

.FormContactUsMobile {
  .modal-content__iconClose {
    display: block !important;
  }
}

.modal-content__iconClose {
  display: none !important;
}