.rating {
  .bold {
    color: $color-yellow;
  }

  .svg-inline--fa {
    width: pxToRem(18);
    margin: pxToRem(2);
  }

  .line {
    color: $color-border
  }
}

@include breakpoint(sm992) {
  .rating {
    .svg-inline--fa {
      width: pxToVW(14);
      margin: pxToVW(2);
    }
  }
}