.form-search {
  margin-bottom: pxToRem(40);
  position: relative;
  display: inline-block;

  .list-autocomplete-search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;

    ul {
      padding: pxToRem(30) 0;
      margin-top: pxToRem(-2);
      min-height: pxToRem(100);
      max-height: pxToRem(400);
      background-color: $white;
      margin: 0;
      border-radius: pxToRem(15);
      border: solid pxToRem(4) $bg-main;
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      overflow-x: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 pxToRem(1) rgba(0, 0, 0, 0.3);
        background-color: $bg-main;
      }

      &::-webkit-scrollbar {
        width: pxToRem(1);
        background-color: $bg-main;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $bg-main;
        border: pxToRem(1) solid $bg-main;
      }

      li {
        height: pxToRem(70);
        font-size: pxToRem(25);
        font-weight: normal;
        line-height: pxToRem(48);
        text-align: left;
        cursor: pointer;
        padding: 0.41667vw 0.88542vw;
        @include transition(0.3s);
        color: $color-light5;

        &:hover {
          background-color: $bg-main;
          color: $white;
        }
      }
    }
  }

  .form-group {
    position: relative;
    display: inline-block;

    .customer-select {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: pxToRem(135);
      // min-width: 120px;
      height: pxToRem(72);

      * {
        cursor: pointer;
        border: 0 !important;
      }

      &:after {
        position: absolute;
        right: pxToRem(-10);
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 9;
        background-image: url('../../../assets/imgs/ic-back-white.png');
        width: pxToRem(25);
        height: pxToRem(25);
        background-size: contain;
        cursor: pointer;
        top: calc(50% - #{pxToRem(10)});
        z-index: -1;
        @include transform-rotate(-90deg);
      }

      .css-1uccc91-singleValue {
        color: $white;
        font-size: pxToRem(15);
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: pxToRem(0.4);
        text-align: left;
      }

      .css-26l3qy-menu {
        padding: pxToRem(8) pxToRem(15) pxToRem(8) pxToRem(12);
        border-radius: pxToRem(10);
        box-shadow: pxToRem(10) pxToRem(10) pxToRem(30) 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
        font-size: pxToRem(15);
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: pxToRem(0.4);
        text-align: left;
        color: $color-light4;
        height: auto !important;

        > div > div {
          padding: pxToRem(5) !important;
          background-color: transparent;
          cursor: pointer;
          font-size: pxToRem(20);
          font-weight: 500;
          line-height: 1.2;
          letter-spacing: pxToRem(0.4);
          text-align: left;
          color: $color-light4;

          &:hover {
            color: $color-blue-light;
          }
        }

        * {
          border: 0 !important;
        }
      }

      .css-26l3qy-menu {
        margin: 0;
      }

      .css-g1d714-ValueContainer,
      .css-2b097c-container {
        height: pxToRem(72);
        padding-left: 0;
        width: pxToRem(135);
        border: 0;

        .css-1uccc91-singleValue {
          padding-left: pxToRem(15);
        }
      }

      .css-2b097c-container {
        > div {
          height: pxToRem(72);
        }
      }

      .css-yk16xz-control {
        background-color: transparent;
        border: 0;
        height: pxToRem(72);

        > div {
          border: 0 !important;
        }

        * {
          background-color: transparent;
        }
      }
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .css-1pahdxg-control {
    min-height: auto;
    max-height: initial;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    border: 0;

    * {
      background-color: transparent;
    }

    &:focus {
      outline: none;
      border: 0;
    }
  }

  &.form2 {
    margin-bottom: 0;
    padding-bottom: pxToRem(90);

    .form-group {
      width: 100%;

      .input__box {
        input {
          width: 100%;
          border-radius: pxToRem(15);
          border: pxToRem(2) solid $bg-main;
          background: $white;
          font-size: pxToRem(20);
          color: $color-black;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          font-weight: normal;
        }
      }

      .basic-multi-select {
        .select__single-value {
          font-size: pxToRem(20);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          color: $color-black;
          font-weight: 500;
        }

        .select__menu {
          margin-top: pxToRem(-2);
          height: pxToRem(420);
          margin: 0;
          border-radius: pxToRem(15);
          border: solid pxToRem(2) $bg-main;
          border-top: 0;
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          .select__menu-list {
            height: 100%;
            max-height: 100% !important;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 pxToRem(1) rgba(0, 0, 0, 0.3);
              background-color: $bg-main;
            }

            &::-webkit-scrollbar {
              width: pxToRem(1);
              background-color: $bg-main;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $bg-main;
              border: pxToRem(1) solid $bg-main;
            }
          }

          .select__option {
            height: pxToRem(70);
            font-size: pxToRem(25);
            font-weight: normal;
            line-height: pxToRem(48);
            text-align: left;
            cursor: pointer;
            @include transition(0.3s);
            color: $color-light5;

            &:hover {
              background-color: $bg-main !important;
              color: $white;
            }
          }
        }

        .css-yk16xz-control {
          border: 0;
          background-color: transparent;
          border-radius: 0;
        }

        input {
          font-size: pxToRem(20) !important;
          color: $color-black !important;
        }

        .select__control--menu-is-open {
          .select__value-container {
            background-color: $bg-light8;
            border-bottom-right-radius: 0;
            border-bottom-color: transparent;
            border-bottom-left-radius: 0;
          }
        }

        .select__value-container {
          padding: 0.78125vw pxToRem(227) 0.67708vw 8.125vw;
          width: 100%;
          border-radius: pxToRem(15);
          border: pxToRem(2) solid $bg-main;
          background-color: $white;
          font-size: pxToRem(20);
          color: $color-black;

          font-weight: normal;
          height: pxToRem(72);
          border-right: 0;

          .select__multi-value__label {
            background-color: $bg-light4;
            font-size: pxToRem(18);
            padding: pxToRem(8) pxToRem(5);
            color: $color-black;
            font-weight: 700;
            text-transform: lowercase;
          }
        }
      }

      .customer-select {
        color: $color-black;

        &:after {
          background-image: url('../../../assets/imgs/arrow-up.png');
          @include transform-rotate(0);
        }

        .css-1uccc91-singleValue {
          color: $color-black;
        }
      }
    }

    .btn-wrap {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;

      button {
        width: pxToRem(227);
        height: pxToRem(72);
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

.form-contact-us {
  .input__error-msg {
    margin-left: 0;
    margin-top: 0;
    text-align: left;
  }

  h2 {
    text-align: center;
    font-size: pxToRem(30);
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: pxToRem(0.4);
    color: $bg-main;
    margin-bottom: pxToRem(32);
  }

  input,
  textarea {
    border-radius: pxToRem(10);
    font-size: pxToRem(15);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: pxToRem(0.4);
    text-align: left;
    color: $color-light4;

    &:hover {
      color: $color-blue-light;
    }

    &::-webkit-input-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(13);
      letter-spacing: pxToRem(-0.25);
      font-style: italic;
    }

    &::placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(13);
      font-style: italic;
      letter-spacing: pxToRem(-0.25);
    }

    &::-moz-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(13);
      font-style: italic;
      letter-spacing: pxToRem(-0.25);
    }

    &:-ms-input-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(13);
      font-style: italic;
      letter-spacing: pxToRem(-0.25);
    }
  }

  .style-line {
    align-items: flex-end;

    &.date-time .w-100 {
      width: auto !important;
    }

    .input__label {
      min-width: pxToRem(200);
      margin-right: pxToRem(10);
      margin-bottom: 0;
      line-height: 1;
      white-space: nowrap;
      position: relative;
      top: pxToRem(5);

      &.w-auto {
        min-width: auto;
      }

      span {
        font-size: pxToRem(15);
        font-weight: normal;
        font-style: italic;
        line-height: 1.4;
      }
    }

    .customer-select {
      width: 100%;
      min-width: pxToRem(100);

      &::after {
        right: 0;
        top: 75%;
      }

      .css-1uccc91-singleValue {
        top: 70%;
      }

      .css-yk16xz-control,
      .css-1pahdxg-control {
        > div {
          border-top: 0;
          border-right: 0;
          border-left: 0;
          padding: 0;
          border-radius: 0;
        }
      }
    }

    input {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      padding-left: 0;
      padding-bottom: 0;
    }

    &.date-time {
      .customer-select {
        width: auto;
        max-width: pxToRem(109);
      }
    }

    .react-datepicker-wrapper {
      input {
        max-width: pxToRem(150);
        background-size: pxToRem(15);
        background-repeat: no-repeat;
        background-image: url('../../../assets/imgs/arrow-up.png');
        background-position: right bottom pxToRem(5);
      }
    }
  }

  .group-btn-file {
    position: relative;
    z-index: 0;

    * {
      cursor: pointer;
    }

    .d-group {
      width: 100%;
    }

    .custom-file-input {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      z-index: 1;
    }

    button {
      width: pxToRem(91);
      height: pxToRem(28);
      padding: pxToRem(6) pxToRem(11) pxToRem(5);
      border-radius: pxToRem(5);
      background-color: #b40706;

      font-size: pxToRem(14);
      font-weight: 500;
      line-height: 1;
    }
  }

  .form-group.btn-group {
    margin-top: pxToRem(20);

    button {
      width: pxToRem(237);
      border-radius: pxToRem(10);
    }
  }
}

.form-search-page {
  margin-bottom: pxToRem(40);
  padding-right: pxToRem(54);

  .form-group {
    margin-bottom: pxToRem(30);
  }

  .group-rating {
    .group-btn-rating {
      margin-left: pxToRem(-5);
    }

    .item {
      padding: pxToRem(5);

      svg {
        color: $bg-light11;
      }

      &.active {
        svg {
          color: $color-yellow;
        }
      }
    }
  }

  .rating {
    margin-left: pxToRem(-6);
    margin-bottom: pxToRem(8);
    cursor: pointer;

    svg.svg-inline--fa {
      margin-left: pxToRem(5);
    }
  }

  .btn-wrap {
    margin-top: pxToRem(30);
  }

  .customer-select {
    &::after {
      z-index: 0;
    }
  }
}

.FormContactUsMobile {
  display: none !important;

  .form-contact-us {
    .react-datepicker-popper {
      transform: none !important;
    }

    .style-line.date-time .customer-select {
      min-width: pxToVW(60);
    }

    .style-line .input__label {
      top: 0;
    }

    .btn-wrap {
      button {
        width: pxToVW(80);
        height: pxToVW(25);
        font-size: pxToVW(11);
        border-radius: pxToVW(8);
      }
    }

    .btn-group {
      .btn-wrap {
        margin-top: pxToVW(20);

        button {
          min-width: pxToVW(110);
          height: pxToVW(35);
          font-size: pxToVW(15);
          padding: pxToVW(5) pxToVW(10);
          width: 100% !important;
          border-radius: pxToVW(10);
        }
      }
    }
  }
}

@include breakpoint(sm992) {
  .FormContactUs {
    display: none !important;
  }

  .FormContactUsMobile {
    display: block !important;
  }

  .css-yk16xz-control {
    min-height: auto !important;
  }

  .form-search {
    margin-bottom: pxToVW(40);

    .list-autocomplete-search {
      ul {
        padding: pxToVW(15) 0;
        margin-top: pxToVW(-2);
        height: pxToVW(200);
        border-radius: pxToVW(6);
        border: solid pxToVW(2) $bg-main;
        max-height: pxToVW(400);

        li {
          height: pxToVW(40);
          font-size: pxToVW(15);
          line-height: pxToVW(28);
          padding: pxToVW(8) pxToVW(10);
        }
      }
    }

    .form-group {
      .customer-select {
        width: pxToVW(70);
        height: pxToVW(40);

        &:after {
          right: pxToVW(-10);
          width: pxToVW(10);
          height: pxToVW(10);
          top: calc(50% - #{pxToVW(3)});
        }

        .css-1uccc91-singleValue {
          font-size: pxToVW(12);
          letter-spacing: pxToVW(0.4);
        }

        .css-26l3qy-menu {
          padding: pxToVW(8) pxToVW(10) pxToVW(8) pxToVW(12);
          border-radius: pxToVW(10);
          font-size: pxToVW(12);
          letter-spacing: pxToVW(0.4);

          > div > div {
            padding: pxToVW(5) !important;
            font-size: pxToVW(12);
            letter-spacing: pxToVW(0.4);
          }
        }

        .css-g1d714-ValueContainer,
        .css-2b097c-container {
          height: pxToVW(40);
          width: pxToVW(100);
          margin-right: pxToVW(-2);

          .css-1uccc91-singleValue {
            padding-left: pxToVW(5);
          }
        }

        .css-2b097c-container {
          > div {
            height: pxToVW(40);
          }
        }

        .css-yk16xz-control {
          height: pxToVW(40);

          .css-1uccc91-singleValue {
            margin-top: 0;
          }
        }
      }
    }

    &.form2 {
      padding-bottom: pxToVW(30);

      .form-group {
        .input__box {
          input {
            border-radius: pxToVW(5);
            border: pxToVW(1) solid $bg-main;
            font-size: pxToVW(15);
          }
        }

        .basic-multi-select {
          .select__single-value {
            font-size: pxToVW(20);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
          }

          .select__menu {
            margin-top: pxToVW(0);
            height: pxToVW(300);
            border-radius: pxToVW(5);
            border: solid pxToVW(1) $bg-main;

            .select__menu-list {
              &::-webkit-scrollbar-thumb {
                border: pxToVW(1) solid $bg-main;
              }
            }

            .select__option {
              height: pxToVW(40);
              font-size: pxToVW(15);
              line-height: pxToVW(25);
              padding: pxToVW(5) pxToVW(15) !important;
            }
          }

          input {
            font-size: pxToVW(15) !important;
          }

          .select__value-container {
            padding: 0 pxToVW(80);
            border-radius: pxToVW(5);
            border: pxToVW(1) solid $bg-main;
            font-size: pxToVW(15);
            font-weight: normal;
            height: pxToVW(40);

            .select__multi-value__label {
              font-size: pxToVW(10);
              padding: pxToVW(4) pxToVW(5);
              text-transform: lowercase;
            }
          }
        }
      }

      .btn-wrap {
        button {
          margin-top: 0;
          width: pxToVW(78);
          height: pxToVW(40);
          font-size: pxToVW(13);
          padding: 0;
        }
      }
    }
  }

  .form-contact-us {
    h2 {
      font-size: pxToVW(20);
      letter-spacing: pxToVW(0.4);
      margin-bottom: pxToVW(22);
    }

    input,
    textarea {
      border-radius: pxToVW(5);
      font-size: pxToVW(15);
      letter-spacing: pxToVW(0.4);

      &::-webkit-input-placeholder {
        font-size: pxToVW(15);
        letter-spacing: pxToVW(-0.25);
      }

      &::placeholder {
        font-size: pxToVW(15);
        letter-spacing: pxToVW(-0.25);
      }

      &::-moz-placeholder {
        font-size: pxToVW(15);
        letter-spacing: pxToVW(-0.25);
      }

      &:-ms-input-placeholder {
        font-size: pxToVW(15);
        letter-spacing: pxToVW(-0.25);
      }
    }

    .style-line {
      .input__label {
        min-width: pxToVW(200);
        margin-right: pxToVW(10);
        top: pxToVW(5);

        span {
          font-size: pxToVW(15);
        }
      }

      .customer-select {
        width: 100%;
        min-width: pxToVW(100);
      }

      &.date-time {
        .customer-select {
          max-width: pxToVW(109);
        }
      }

      .react-datepicker-wrapper {
        input {
          max-width: pxToVW(150);
          background-size: pxToVW(15);
          background-position: right bottom pxToVW(5);
        }
      }
    }

    .group-btn-file {
      button {
        width: pxToRem(91);
        height: pxToRem(28);
        padding: pxToRem(6) pxToRem(11) pxToRem(5);
        border-radius: pxToRem(5);
        font-size: pxToRem(14);
      }
    }

    .form-group.btn-group {
      margin-top: pxToRem(20);

      button {
        width: pxToRem(237);
        border-radius: pxToRem(10);
      }
    }
  }

  .form-search-page {
    margin-bottom: pxToVW(40);
    padding-right: 0;

    .form-group {
      margin-bottom: pxToVW(17);
    }

    .rating {
      margin-left: pxToVW(-6);
      margin-bottom: pxToVW(8);

      svg.svg-inline--fa {
        margin-left: pxToVW(5);
      }
    }

    .btn-wrap {
      margin-top: pxToVW(30);

      button {
        font-size: pxToVW(10);
        height: pxToVW(32);
      }
    }
  }
}

.select__option--is-selected {
  background-color: $bg-main !important;
  color: $white !important;
}

.select__single-value {
  overflow: initial !important;
}

.form-submit-warranty {
  background-color: #d9d9d9;
  padding: 10px 20px;

  h3 {
    font-family: 'Bebas';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.74;
    letter-spacing: normal;
    text-align: left;
    color: #b40706;
  }
  .form-control {
    border-radius: 0;
  }

  .btn-submit {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(183, 4, 4, 0.9);
    cursor: pointer;
  }
}
