.wrap-gallery {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 999;
  background-color: rgba($color-black, 0.5);
}

.image-gallery {
  position: fixed;
  height: pxToRem(700);
  width: pxToRem(1200);
  margin-top: pxToRem(10);
  background-color: rgba($color-black, 0.5);
  z-index: 9999;
  top: 47%;
  left: 50%;
  @include transform-translate(-50%, -50%);

  .image-gallery-content {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      width: 60px;
      height: 60px;
    }

    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: pxToRem(20);
    top: 40%;
    left: 50%;
    position: absolute;
    @include transform-translate(-50%, -50%);

    .image-gallery-slide-wrapper {
      background-color: $bg-light12;
      box-shadow: 0 pxToRem(3) pxToRem(30) 0 rgba(0, 0, 0, 0.16);
      border-top-left-radius: pxToRem(20);
      border-top-right-radius: pxToRem(20);

      * {
        border-top-left-radius: pxToRem(20);
        border-top-right-radius: pxToRem(20);
      }
    }

    .image-gallery-swipe {
      img {
        border-top-left-radius: pxToRem(20);
        border-top-right-radius: pxToRem(20);
      }
    }

    .image-gallery-thumbnails-wrapper {
      padding: pxToRem(30);
      background-color: $bg-light12;
      border-bottom-left-radius: pxToRem(20);
      border-bottom-right-radius: pxToRem(20);
      box-shadow: 0 pxToRem(3) pxToRem(30) 0 rgba(0, 0, 0, 0.16);
      position: absolute;
      width: 100%;
      bottom: pxToRem(-100);

      .image-gallery-thumbnail {
        width: pxToRem(150);
        margin-right: pxToRem(5);
        border-radius: pxToRem(10);
        background-color: $white;

        * {
          border-radius: pxToRem(8);
          height: pxToRem(70);
        }
      }
    }
  }
}


@include breakpoint(sm992) {

  .image-gallery {
    position: fixed;
    height: pxToRem(240);
    width: 90%;
    margin-top: pxToVW(10);
    top: 30%;

    .image-gallery-content {

      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        width: pxToVW(40);
        height: pxToVW(40);
      }

      border-radius: pxToVW(10);

      .image-gallery-slide-wrapper {
        box-shadow: 0 pxToVW(3) pxToVW(30) 0 rgba(0, 0, 0, 0.16);
        border-top-left-radius: pxToVW(10);
        border-top-right-radius: pxToVW(10);

        * {
          border-top-left-radius: pxToVW(10);
          border-top-right-radius: pxToVW(10);
        }
      }

      .image-gallery-swipe {
        img {
          border-top-left-radius: pxToVW(5);
          border-top-right-radius: pxToVW(5);
        }
      }

      .image-gallery-thumbnails-wrapper {
        padding: pxToVW(15);
        border-bottom-left-radius: pxToVW(5);
        border-bottom-right-radius: pxToVW(5);
        box-shadow: 0 pxToVW(3) pxToVW(30) 0 rgba(0, 0, 0, 0.16);
        bottom: auto;

        .image-gallery-thumbnail {
          width: pxToVW(70);
          margin-right: pxToVW(5);
          border-radius: pxToVW(5);
          border: 1px solid transparent;

          &.active {
            border: 2px solid $bg-main;
          }

          * {
            border-radius: pxToVW(5);
            height: pxToVW(50);
          }
        }
      }
    }
  }

}