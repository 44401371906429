.page-search {
  background-color: $bg-light6;
  padding: pxToRem(80) 0 0 pxToRem(90);
  position: relative;

  .icon-sort-mobile {
    display: none;
  }

  .form-search {
    padding-right: pxToRem(90);
  }

  .content {
    .content-left {
      width: pxToRem(300);

      .filter-sort {
        h3 {
          font-size: pxToRem(30);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          color: $bg-main;
          line-height: 1.2;
          margin-bottom: pxToRem(30);
        }
      }
    }

    .content-right {
      padding: pxToRem(75) pxToRem(60) pxToRem(75) pxToRem(60);
      width:calc(100% - #{pxToRem(300)});
      box-shadow: 0 pxToRem(3) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
      background-color: $white;
      border-top-left-radius: pxToRem(30);
    }
  }
}

.content-search {
  min-height: 60vh;

  .item {
    margin-bottom: pxToRem(40);
  }
}

.item-search {
  height: 100%;
  border-radius: pxToRem(20);
  box-shadow: 0 pxToRem(3) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
  padding-bottom: pxToRem(10);
  text-align: center;
  @include transition(0.3s all);
  border: pxToRem(4) solid transparent;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    border: pxToRem(4) solid $bg-main;
  }

  .bg-image {
    min-height: pxToRem(356);
    width: 100%;
    border-top-left-radius: pxToRem(20);
    border-top-right-radius: pxToRem(20);
    background-position: center center;
    background-size: cover;
  }

  a h3 {
    text-transform: uppercase;
    font-size: pxToRem(25);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    color: $bg-main;
    margin-bottom: pxToRem(12);
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 3.125vw;
  }

  .gr-footer {
    padding: pxToRem(18);

    .hashtag {
      font-size: pxToRem(20);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: pxToRem(30);
      text-align: left;
      color: $color-black;
      margin-right: pxToRem(10);
      position: relative;
      padding-left: pxToRem(35);

      div {
        width: pxToRem(29);
        height: pxToRem(29);
        background-color: $color-black;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
      }
    }

    .wrap-location {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >.rating {
        margin-top: pxToRem(25);
      }

    }

    .location {
      display: block;
      text-align: left;
      margin-top: pxToRem(25);
      font-size: pxToRem(20);
      font-weight: normal;
      font-style: italic;
      line-height: 1.5;
      color: $bg-light7;

      svg {
        margin-right: pxToRem(8);
      }
    }
  }
}

.content-left {
  .overflow {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 9999;
    bottom: 0;
    width: 40%;
    height: 100%;
    background-color: rgba($color-black, 0.4);
    visibility: hidden;
    opacity: 0;
    @include transition(0.5s all);
  }

  &.active {
    .overflow {
      left: auto;
      right: 0;
      visibility: visible;
      opacity: 1;
    }
  }
}

@include breakpoint(sm992) {
  .page-search {
    padding: pxToVW(60) pxToVW(20) pxToVW(60) pxToVW(20);

    .sort-mobile {
      display: flex;
      justify-content: space-between;

      .form-search {
        width: 100%;
      }
    }

    .icon-sort-mobile {
      display: block;
      width: pxToVW(40);
      height: pxToVW(40);
      margin-right: pxToVW(8);
      margin-top: 1px;

      img {
        width: 100%;
      }
    }

    .form-search {
      padding-right: 0
    }

    .content {
      .content-left {
        position: fixed;
        left: -100%;
        z-index: 99999;
        background-color: $white;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 60%;
        padding: pxToVW(86) pxToVW(30);
        visibility: hidden;
        opacity: 0;
        @include transition(0.5s all);

        &.active {
          left: 0;
          visibility: visible;
          opacity: 1;
        }

        .filter-sort {
          h3 {
            font-size: pxToVW(18);
            margin-bottom: pxToVW(17);
          }
        }
      }

      .content-right {
        padding: 0;
        width: 100%;
        box-shadow: none;
        border-top-left-radius: 0;
        background-color: transparent;

        .row {
          margin-left: pxToVW(-7);
          margin-right: pxToVW(-7);
        }

        .item {
          padding: 0 pxToVW(7);
        }
      }
    }
  }

  .content-search {

    .item {
      margin-bottom: pxToVW(15);
    }
  }

  .item-search {
    border-radius: pxToVW(10);
    box-shadow: 0 pxToVW(3) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
    padding-bottom: 0;
    border: pxToVW(2) solid transparent;

    &:hover,
    &:active,
    &:focus {
      border: pxToVW(2) solid $bg-main;
    }

    .bg-image {
      min-height: pxToVW(145);
      border-top-left-radius: pxToVW(10);
      border-top-right-radius: pxToVW(10);
    }

    a h3 {
      font-size: pxToVW(13);
      margin-bottom: pxToVW(10);

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: pxToVW(30);
    }

    .gr-footer {
      padding: pxToVW(10) pxToVW(8);

      .hashtag {
        font-size: pxToVW(10);
        line-height: 1.4;
        margin-right: pxToVW(10);
        padding-left: pxToVW(25);

        div {
          width: pxToVW(20);
          height: pxToVW(20);
        }
      }

      .rating .svg-inline--fa {
        width: pxToVW(8);
        margin: pxToVW(1);
      }

      .location {
        margin-top: pxToVW(5);
        font-size: pxToVW(12);

        svg {
          margin-right: pxToVW(5);
          width: pxToVW(10);
          height: pxToVW(10);
        }
      }
    }
  }
}