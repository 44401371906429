.page-construction {
  padding: pxToRem(60) pxToRem(97);

  .project-info {
    margin-top: pxToRem(30);

    .project-title {
      font-size: pxToRem(50);
      font-weight: bold;
      line-height: 0.8;
      color: $bg-main;
      margin-bottom: pxToRem(16);
    }

    .project-sub,
    .project-address {
      font-size: pxToRem(25);
      font-weight: normal;
      line-height: 1.6;
      color: $color-black;
    }
  }

  .project-total {
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
    margin-top: pxToRem(50);

    ul {
      li {

        font-size: pxToRem(22);
        font-weight: 500;
        line-height: 1.41;
        text-align: left;
        display: flex;
        color: $color-black;
        margin-bottom: pxToRem(16);

        label {
          min-width: pxToRem(240);
          margin-right: pxToRem(60);
        }

        &.total {
          font-weight: bold;

          div {
            color: $bg-main;
          }
        }
      }
    }
  }
}

.page-title {
  font-size: pxToRem(50);
  font-weight: bold;
  line-height: 0.8;
  text-align: center;
  color: $bg-main;
}

.pt-page {
  padding-top: pxToRem(100);
}

.table-construction {
  margin-top: pxToRem(36);
  box-shadow: 0 pxToRem(6) pxToRem(6) 0 rgba(0, 0, 0, 0.16);
  border-radius: pxToRem(10);
  border: solid pxToRem(1) $color-border;
  overflow: auto;

  &.isShowModal .table-no-data {
    height: 180px;
  }

  .table-header {
    position: relative;
    height: pxToRem(68);
    @include bgMain();
    justify-content: space-between;
    border-top-left-radius: pxToRem(10);
    border-top-right-radius: pxToRem(10);
    min-width: 1360px;

    .t-item {
      text-align: center;
      line-height: 1.4;
      font-size: pxToRem(18);
      font-weight: 500;
      padding: 0 pxToRem(10);
      display: flex;
      align-items: center;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      position: relative;
      justify-content: center;
    }
  }

  .b-item1,
  .t-item1 {
    width: 15%;
    min-width: 100px;
  }

  .b-item2,
  .t-item2 {
    width: 25%;
    min-width: 200px;
  }

  .b-item3,
  .t-item3 {
    width: 10%;
    min-width: 120px;
    text-align: center;
  }

  .b-item4,
  .t-item4 {
    width: 7%;
    min-width: 120px;
    text-align: center
  }

  .b-item5,
  .t-item5 {
    width: 10%;
    min-width: 150px;
    text-align: center
  }

  .b-item6,
  .t-item6 {
    width: 10%;
    min-width: 150px;
    text-align: center
  }

  .b-item7,
  .t-item7 {
    width: 8%;
    text-align: center;
    min-width: 120px;
  }

  .b-item8,
  .t-item8 {
    width: 8%;
    text-align: center;
    min-width: 100px;
  }

  .b-item9,
  .t-item9 {
    text-align: center;
    width: 8%;
    min-width: 100px;
  }

  .table-body {
    min-width: 1360px;

    .item-table {
      background-color: $white;
      padding: pxToRem(30) pxToRem(10);
      align-items: center;
      cursor: pointer;
      @include transition(0.3s all);

      &:nth-child(2n+2) {
        background-color: rgba($bg-main, 0.05);
      }

      &:hover {
        background-color: rgba($bg-main, 0.34);
      }

      .b-item {
        font-size: pxToRem(20);
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: normal;
        color: $color-black;
        padding: 0 pxToRem(5);

        &.b-item1 {
          .b-item-title {
            text-transform: uppercase;
            color: $bg-main;
          }
        }

        &.b-item8 {
          font-size: pxToRem(18);
          font-weight: normal;
          font-style: italic;
        }

        li,
        .b-item-desc {
          font-size: pxToRem(16);
          font-weight: normal;
          font-style: normal;
          line-height: 1.94;
          white-space: pre-line;
        }
      }

      .b-item-sub-title {
        color: $color-black;
      }

      .btn-wrap {
        button {
          border-radius: pxToRem(8);
          min-width: pxToRem(112);
          font-size: pxToRem(15);
          font-weight: 500;
          height: pxToRem(36);
        }
      }
    }
  }
}

.modal-sort {
  position: absolute;
  top: calc(100% - 10px);
  right: -30px;
  width: pxToRem(240);
  min-height: pxToRem(160);
  padding: pxToRem(15);
  background-color: $bg-light4;
  border-radius: pxToRem(5);
  box-shadow: 0 pxToRem(6) pxToRem(10) 0 rgba(0, 0, 0, 0.16);

  .form-group {
    margin-bottom: 12px;
  }

  .input__label,
  .error-msg,
  .css-1uccc91-singleValue {
    font-size: pxToRem(15);
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: pxToRem(0.15);
  }

  .customer-select {
    &::after {
      width: pxToRem(10);
      height: pxToRem(10);
      right: pxToRem(10);
      top: 48%;
      z-index: 0;
    }

    .css-26l3qy-menu {

      >div>div {
        font-size: pxToRem(15);
      }
    }

    .css-1wa3eu0-placeholder {
      font-weight: normal;
      font-size: pxToRem(13);
    }

    .css-yk16xz-control {
      min-height: pxToRem(35);
    }

    .input__box,
    .css-2b097c-container {
      height: pxToRem(35);
    }

    .css-yk16xz-control>div,
    .css-1pahdxg-control>div {
      height: pxToRem(35);
      border-radius: pxToRem(5);
      font-size: pxToRem(10);
      font-weight: normal;
      line-height: 1.3;
      letter-spacing: pxToRem(0.25);
      text-align: center;
    }
  }

  .btn-wrap {
    margin-top: pxToRem(6);

    button {
      margin-bottom: 0;
      width: pxToRem(78);
      height: pxToRem(35);
      border-radius: pxToRem(5);
      font-size: pxToRem(15);
      font-weight: normal;
      line-height: 1.3;
      letter-spacing: pxToRem(0.25);
      text-align: center;
    }
  }
}

.table-no-data {
  border-bottom-right-radius: pxToRem(10);
  text-align: center;
  font-size: pxToRem(30);
  padding: pxToRem(30);
  text-transform: uppercase;
  color: $color-light1;
  border-bottom-left-radius: pxToRem(10);
}


@include breakpoint(lg1280) {
  .table-construction {

    .table-header {
      min-width: 1130px;
    }

    .b-item1,
    .t-item1 {
      width: 12%;
      min-width: 80px;
    }

    .b-item2,
    .t-item2 {
      width: 15%;
      min-width: 250px;
    }

    .b-item3,
    .t-item3 {
      width: 8%;
      min-width: 100px;
      text-align: center;
    }

    .b-item4,
    .t-item4 {
      width: 7%;
      min-width: 100px;
      text-align: center
    }

    .b-item5,
    .t-item5 {
      width: 8%;
      min-width: 100px;
      text-align: center
    }

    .b-item6,
    .t-item6 {
      width: 10%;
      min-width: 120px;
      text-align: center
    }

    .b-item7,
    .t-item7 {
      width: 8%;
      text-align: center;
      min-width: 100px;
    }

    .b-item8,
    .t-item8 {
      width: 8%;
      text-align: center;
      min-width: 120px;
    }

    .b-item9,
    .t-item9 {
      text-align: center;
      width: 8%;
      min-width: 100px;
    }

    .table-body {
      min-width: 1130px;
    }
  }
}

span.hashtag {
  font-size: pxToRem(15);
  line-height: pxToRem(24);
  color: $white;
  padding: pxToRem(5) pxToRem(6);
  background-color: $color-tag;
  border-radius: pxToRem(15);
  display: inline-block !important;
  margin: pxToRem(3);
  width: auto !important;
}

@include breakpoint(sm992) {
  span.hashtag {
    font-size: pxToVW(13);
    line-height: pxToVW(20);
    color: $white;
    padding: pxToVW(3) pxToVW(6);
    background-color: $color-tag;
    border-radius: pxToVW(10);
    display: inline-block !important;
    margin: pxToVW(3);
    width: auto !important;
  }

  .page-construction {
    padding: pxToVW(40) pxToVW(20);

    .project-info {
      margin-top: pxToVW(30);

      .project-title {
        font-size: pxToVW(18);
        margin-bottom: pxToVW(16);
      }

      .project-sub,
      .project-address {
        font-size: pxToVW(15);
      }
    }

    .project-total {
      margin-top: pxToVW(30);

      ul {
        li {

          font-size: pxToVW(18);
          margin-bottom: pxToVW(16);

          label {
            min-width: pxToVW(200);
            margin-right: pxToVW(30);
          }
        }
      }
    }
  }

  .page-title {
    font-size: pxToVW(20);
  }

  .pt-page {
    padding-top: pxToVW(60);
  }

  .table-construction {
    margin-top: pxToVW(36);
    box-shadow: 0 pxToVW(6) pxToVW(6) 0 rgba(0, 0, 0, 0.16);
    border-radius: pxToVW(6);
    border: solid pxToVW(1) $bg-main;
    overflow: auto;

    .table-header {
      height: pxToVW(40);
      border-top-left-radius: pxToVW(6);
      border-top-right-radius: pxToVW(6);
      min-width: 1450px;
      padding-left: 15px;
      padding-right: 15px;

      .t-item {
        font-size: pxToVW(12);
        padding: 0 pxToVW(10);

        .ico-up {
          left: pxToVW(8);
        }
      }
    }

    .b-item1,
    .t-item1 {
      width: 15%;
      min-width: 200px;
    }

    .b-item2,
    .t-item2 {
      width: 25%;
      min-width: 220px;
    }

    .b-item3,
    .t-item3 {
      width: 10%;
      min-width: 120px;
      text-align: center;
    }

    .b-item4,
    .t-item4 {
      width: 7%;
      min-width: 120px;
      text-align: center
    }

    .b-item5,
    .t-item5 {
      width: 10%;
      min-width: 150px;
      text-align: center
    }

    .b-item6,
    .t-item6 {
      width: 10%;
      min-width: 150px;
      text-align: center
    }

    .b-item7,
    .t-item7 {
      width: 8%;
      text-align: center;
      min-width: 120px;
    }

    .b-item8,
    .t-item8 {
      width: 8%;
      text-align: center;
      min-width: 100px;
    }

    .b-item9,
    .t-item9 {
      text-align: center;
      width: 8%;
      min-width: 100px;
    }

    .table-body {
      min-width: 1450px;


      .item-table {
        padding: pxToVW(30) pxToVW(10);

        .b-item {
          font-size: pxToVW(15);
          padding: 0 pxToVW(5);


          &.b-item8 {
            font-size: pxToVW(10);
          }

          li,
          .b-item-desc {
            font-size: pxToVW(12);
          }
        }

        .btn-wrap {
          button {
            border-radius: pxToVW(8);
            min-width: pxToVW(112);
            font-size: pxToVW(15);
            font-weight: 500;
            height: pxToVW(36);
          }
        }
      }
    }
  }

  .modal-sort {
    position: absolute;
    top: calc(100% - 10px);
    right: 0;
    width: pxToVW(160);
    min-height: pxToVW(150);
    padding: pxToVW(15);
    border-radius: pxToVW(5);
    box-shadow: 0 pxToVW(6) pxToVW(10) 0 rgba(0, 0, 0, 0.16);

    .input__label,
    .error-msg,
    .css-1uccc91-singleValue {
      font-size: pxToVW(13);
      letter-spacing: pxToVW(0.15);
    }

    .customer-select {
      &::after {
        width: pxToVW(10);
        height: pxToVW(10);
        right: pxToVW(10);
        top: 48%;
        z-index: 0;
      }

      .css-26l3qy-menu {
        min-height: pxToVW(100);

        >div {
          min-height: pxToVW(100);
        }

        >div>div {
          font-size: pxToVW(12);
        }
      }

      .css-1wa3eu0-placeholder {
        font-weight: normal;
        font-size: pxToVW(10);
      }

      .css-yk16xz-control {
        min-height: pxToVW(23);
      }

      .input__box,
      .css-2b097c-container {
        height: pxToVW(23);
      }

      .css-yk16xz-control>div,
      .css-1pahdxg-control>div {
        height: pxToVW(23);
        border-radius: pxToVW(5);
        font-size: pxToVW(10);
        letter-spacing: pxToVW(0.25);
      }
    }

    .btn-wrap {
      margin-top: pxToVW(6);

      button {
        width: pxToVW(78);
        height: pxToVW(24);
        border-radius: pxToVW(5);
        font-size: pxToVW(10);
        letter-spacing: pxToVW(0.25);
      }
    }
  }

  .table-no-data {
    border-bottom-right-radius: pxToVW(10);
    font-size: pxToVW(20);
    padding: pxToVW(20);
    border-bottom-left-radius: pxToVW(10);
  }
}

table {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 40px 0px;
  border-spacing: 0px;

  thead {
    @include bgMain();

    th {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
      padding: 20px;

      text-transform: uppercase;
      white-space: nowrap;
      color: $white;

      &:nth-child(1) {
        min-width: 60px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 200px;
      }

      &:nth-child(4) {
        min-width: 100px;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover td {
        background: rgb(242, 243, 245);
      }

      td {
        padding: 10px 30px;
        font-size: 16px;
        border-bottom: 2px solid rgb(250, 250, 250);
        background-color: white;

      }
    }
  }

  @include breakpoint(sm992) {

    thead {
      th {
        padding: pxToVW(10) pxToVW(10) pxToVW(10);
        min-width: auto !important;

        // &:nth-child(1) {
        //   display: none !important;
        // }

        font-size: pxToVW(14);
        text-align: center;

        &:nth-child(4) {
          min-width: pxToVW(155);
        }
      }
    }

    tbody {
      tr {

        td {
          padding: pxToVW(10) pxToVW(10) pxToVW(10);
          font-size: pxToVW(14);

          &:nth-child(1) {
            // display: none !important;
          }
        }
      }
    }
  }
}

.h-50vh {
  min-height: 50vh;
}