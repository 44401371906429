.input__box {
  text-align: left;

  .css-1pahdxg-control {
    min-height: auto;
    max-height: initial;
    box-shadow: none;
    border-color: transparent;
    background-color: transparent;

    >div {
      border: 0;
    }

    * {
      background-color: transparent;
    }
  }
}

.css-tlfecz-indicatorContainer {
  max-height: pxToRem(35);
}

.css-b8ldur-Input {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;

  * {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    font-size: 1.04167vw !important;
    text-align: left !important;
    display: block !important;
  }
}

.select__indicators {
  display: none !important;
}

.css-4ljt47-MenuList {
  padding: 0 !important;
  font-size: pxToRem(16) !important;
}

.css-9gakcf-option {
  // background-color: $athens-gray-2 !important;
  // color: $color-border-active !important;
  border-top: pxToRem(1) solid $color-border;
  border-bottom: pxToRem(1) solid $color-border;
  font-weight: $medium;
  padding: pxToRem(8) pxToRem(17) !important;
  letter-spacing: pxToRem(-0.33);

  &:nth-child(1) {
    border-top: 0;
  }
}

// .css-9gakcf-option:active {
//   background-color: $athens-gray-2;
// }

.css-yt9ioa-option,
.css-1n7v3ny-option {
  padding: pxToRem(8) pxToRem(17) !important;
  letter-spacing: pxToRem(-0.33);
}

.css-1n7v3ny-option {
  background-color: transparent !important;
}

.css-4ljt47-MenuList {
  max-height: pxToRem(220) !important;
}

.customer-select {
  position: relative;

  .css-1wy0on6 {
    display: none !important
  }

  .css-1wa3eu0-placeholder {
    color: $color-placeholder;
    font-weight: $regular;
    font-size: pxToRem(13);
    letter-spacing: pxToRem(-0.25);
    font-style: italic;
  }

  .css-1uccc91-singleValue {
    overflow: initial;
    cursor: pointer;
    font-size: pxToRem(13);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: pxToRem(0.4);
    text-align: left;
    color: $color-light4;

    &:hover {
      color: $color-blue-light;
    }
  }

  .input__box,
  .css-2b097c-container {
    height: pxToRem(44);
  }

  &:after {
    position: absolute;
    right: pxToRem(15);
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../assets/imgs/arrow-up.png");
    width: pxToRem(15);
    height: pxToRem(15);
    background-position: center center;
    cursor: pointer;
    top: 55%;
    z-index: -1;
    @include transform-translateY(-50%)
  }

  * {
    cursor: pointer;

  }

  .css-yk16xz-control {
    * {
      background-color: transparent !important;
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .css-g1d714-ValueContainer {
    height: pxToRem(44);
    outline: none;
    border: 0;

    &:focus {
      outline: none;
      border: pxToRem(1) solid $bg-main;
    }
  }

  .css-26l3qy-menu {
    margin: 0;
    padding: pxToRem(8) pxToRem(0) pxToRem(8) pxToRem(12);
    border-radius: pxToRem(10);
    box-shadow: pxToRem(10) pxToRem(10) pxToRem(30) 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    font-size: pxToRem(20);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: pxToRem(0.4);
    text-align: left;
    color: $color-light4;

    >div>div {
      padding: pxToRem(5) !important;
      background-color: transparent;
      cursor: pointer;
      font-size: pxToRem(15);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: pxToRem(0.4);
      text-align: left;
      color: $color-light4;

      &:hover {
        color: $color-blue-light;
      }
    }

    * {
      border: 0 !important;
    }
  }

  &.red {

    .css-yk16xz-control,
    .css-1pahdxg-control {
      >div {
        border-color: $red;
      }
    }
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: 0;
    background-color: transparent;

    >div {
      border: pxToRem(1) solid $color-border;
      height: pxToRem(44);
      display: block;
      font-size: pxToRem(16);
      padding: pxToRem(5);
      color: $color-black;
      width: 100%;
      letter-spacing: pxToRem(-0.25);
      width: 100%;
      border-radius: pxToRem(10);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $regular;
      border-radius: 5px;

      &:last-child {
        display: none !important;
      }

      &::-webkit-input-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(14);
        letter-spacing: pxToRem(-0.25);
      }

      &::placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(14);
        letter-spacing: pxToRem(-0.25);
      }

      &::-moz-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(14);
        letter-spacing: pxToRem(-0.25);
      }

      &:-ms-input-placeholder {
        color: $color-placeholder;
        font-weight: $regular;
        font-size: pxToRem(14);
        letter-spacing: pxToRem(-0.25);
      }
    }
  }
}


@include breakpoint(sm992) {
  .css-b8ldur-Input {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;

    * {
      margin: 0 !important;
      padding: 0 !important;
      height: 100% !important;
      font-size: 14px !important;
    }
  }

  .css-tlfecz-indicatorContainer {
    max-height: pxToVW(35);
  }

  .css-4ljt47-MenuList {
    font-size: pxToVW(20) !important;
  }

  .css-9gakcf-option {
    border-top: pxToVW(1) solid $color-border;
    border-bottom: pxToVW(1) solid $color-border;
    padding: pxToVW(8) pxToVW(17) !important;
    letter-spacing: pxToVW(-0.33);
  }

  .css-yt9ioa-option,
  .css-1n7v3ny-option {
    padding: pxToVW(8) pxToVW(17) !important;
    letter-spacing: pxToVW(-0.33);
  }

  .css-4ljt47-MenuList {
    max-height: pxToVW(220) !important;
  }

  .customer-select {

    .css-1wa3eu0-placeholder {
      font-size: pxToVW(15);
      letter-spacing: pxToVW(-0.25);
    }

    .css-1uccc91-singleValue {
      font-size: pxToVW(15);
      letter-spacing: pxToVW(0.4);
    }

    .input__box,
    .css-2b097c-container {
      height: pxToVW(40);
    }

    &:after {
      right: pxToVW(15);
      width: pxToVW(15);
      height: pxToVW(15);
    }

    .css-g1d714-ValueContainer {
      height: pxToVW(40);

      &:focus {
        border: pxToVW(1) solid $bg-main;
      }
    }

    .css-26l3qy-menu {
      padding: pxToVW(3) pxToRem(0) pxToVW(3) pxToVW(10);
      border-radius: pxToVW(5);
      box-shadow: pxToVW(10) pxToVW(10) pxToVW(30) 0 rgba(0, 0, 0, 0.16);
      font-size: pxToVW(15);
      letter-spacing: pxToVW(0.4);

      >div>div {
        padding: pxToVW(5) !important;
        font-size: pxToVW(13);
        letter-spacing: pxToVW(0.4);
      }
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {

      >div {
        border: pxToVW(1) solid $color-border;
        height: pxToVW(42);
        font-size: pxToVW(15);
        padding: pxToVW(5);
        letter-spacing: pxToVW(-0.25);
        border-radius: pxToVW(5);

        &::-webkit-input-placeholder {
          font-size: pxToVW(14);
          letter-spacing: pxToVW(-0.25);
        }

        &::placeholder {
          font-size: pxToVW(14);
          letter-spacing: pxToVW(-0.25);
        }

        &::-moz-placeholder {
          font-size: pxToVW(14);
          letter-spacing: pxToVW(-0.25);
        }

        &:-ms-input-placeholder {
          font-size: pxToVW(14);
          letter-spacing: pxToVW(-0.25);
        }
      }

      &:focus {
        border: 0 !important;

        >div {
          border: 0 !important;
        }
      }
    }
  }
}