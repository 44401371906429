$font-size-base: 16;
$font-size-base-response: 20;
$border-width-base: 2;
$viewPort: 414;
$viewPort1920: 1300;
// font weight
$Roboto-Font: 'Roboto',
    'sans-serif';
$Lato-Font: 'Lato',
    'sans-serif';
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;

/**
 * Variable for colors
*/
$white: #fff;
$color_primary: #2a5299;
$bg_main: #b40706;
$bg-black: #1d1d1d;
$bg-footer: #fcfcfc;
$border-footer: #f5f5f5;
$color-title-point: #3f3f3f;
$btn-color: rgba(0, 0, 0, 0.16);
$bg-light: #5e5e5e;
$bg-light8: #e5e5e5;
$color-light5: #3a3a3a;
$color-black: #000000;
$color-light2: #676767;
$color-light3: #fafafa;
$color-border: #707070;
$color-placeholder: #b6b6b6;
$red: #f20000;
$color-light0: #2a2a2a;
$color-light1: #b2b2b2;
$color-light4: #2a2827;
$color-blue-light: #1e396b;
$bg-main2: #1667c4;
$bg-disabled: #bfbfbf;
$color-yellow: #ffb303;
$bg-light4: #ececec;
$bg-light5: #d7dae2;
$color-light1: #4d4f5c;
$bg-light6: #f6f6f6;
$bg-light7: #7b7b7b;
$bg-black2: #030504;
$bg-light9: #d4d4d4;
$bg-light10: #f8f8f8;
$bg-light11: #7d7d7d;
$bg-black3: #1a1818;
$bg-light12: #636363;
$color-tag: #7d7d7d;
$text-black: #030303;
$bg-red: rgba(183, 4, 4, 0.9);