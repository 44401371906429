/*
* function: pxToRem
* Used to convert unit 'px' to 'rem'
*/

@function pxToRem($size) {
  $vw: (
    $viewPort1920 * 0.01
  );
@return ($size / $vw) * 1vw;
}

/*
* function: pxToVH
* Used to convert unit 'px' to 'vh'
*/
@function pxToVH($size) {
  $vh: (
    $viewPort * 0.01
  );
@return ($size / $vh) * 1vh;
}

/*
* function: pxToVW
* Used to convert unit 'px' to 'vw'
*/
@function pxToVW($size) {
  @return ($size / $viewPort) * 100vw;
}