.progress-wrap .progress {
  border-radius: pxToRem(20);
  background-color: $white;
  border: solid pxToRem(1) $color-border;
  height: pxToRem(20);

  .progress-bar {
    height: pxToRem(18);
    background: rgb(42, 82, 153);
    background: linear-gradient(-90deg, rgba(42, 82, 153, 1) 0%, rgba(21, 41, 77, 1) 100%);
    border-radius: pxToRem(20);
    transition: 2s linear;
    width: 50%;
    transition-property: width, background-color;
    font-size: pxToRem(13);
    color: $white;
    font-weight: 500;
    line-height: pxToRem(16);
    transition-delay: 3s;
  }
}


@include breakpoint(sm992) {
  .progress-wrap .progress {
    border-radius: pxToVW(10);
    border: solid pxToRem(1) $bg-main;
    height: pxToVW(15);

    .progress-bar {
      height: pxToVW(15);
      border-radius: pxToVW(10);
      font-size: pxToVW(13);
      line-height: 1;
    }
  }
}