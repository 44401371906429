$themeColor: $bg-light7 !default;
$colors: (
  'white': #ffffff,
  'black': #000000,
  ) !default;


:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev2,
.swiper-button-next2 {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27
  );
height: var(--swiper-navigation-size);
margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
z-index: 10;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: var(--swiper-navigation-color, var(--swiper-theme-color));

&.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

&:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  color: $themeColor;
  font-weight: bold;
}

&:hover {
  &::after {
    color: #b40706;
  }
}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-prev2 {
  &:after {
    content: 'prev';
  }

  left: 3px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-next2 {
  &:after {
    content: 'next';
  }

  right: 3px;
  left: auto;
}

@each $navColorName,
$navColorValue in $colors {

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev2,
  .swiper-button-next2 {
    &.swiper-button-#{$navColorName} {
      --swiper-navigation-color: #{$navColorValue};
    }
  }
}

.swiper-button-lock {
  display: none;
}