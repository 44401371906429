.footer {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);
  @include bgMain();
  padding: pxToRem(75) pxToRem(58) pxToRem(40) pxToRem(58);

  .logo-footer {
    max-width: pxToRem(200);
    text-align: center;
    margin: auto;

    a {
      display: block;
    }

    img {
      margin: auto;
      width: 100%;
    }
  }

  .logo-copyright {
    margin: auto;
    text-align: center;
  }

  .info-company {
    h3 {
      font-size: pxToRem(35);
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      color: $white;
      margin-bottom: pxToRem(15);
      text-transform: uppercase;
    }

    .company-desc {
      opacity: 0.9;
      margin-bottom: pxToRem(30);
      font-size: pxToRem(15);
      font-style: italic;
      line-height: 1.5;
      text-align: left;
      color: $white;
    }

    .address {
      color: $white;
      margin-bottom: pxToRem(20);
    }

    .address-title {
      font-size: pxToRem(20);
      font-weight: 500;
      line-height: 1.23;
      margin-bottom: pxToRem(10);
    }

    .address-branch {
      li {
        list-style: disc;
        margin-left: 17px;
        @media (max-width: 600px) {
         margin-left: 10px;
        }
      }
    }

    .address-name {
      font-size: pxToRem(15);
      line-height: 1.5;
      opacity: 0.9;
      font-family: 'Montserrat';
    }

    .address-company {
      color: $white;

      ul {
        width: 100%;

        li {
          width: 100%;

          img {
            margin-right: pxToRem(10);
          }
        }
      }
    }

    .copyright {
      margin-top: pxToRem(60);
      font-size: pxToRem(25);
      opacity: 0.9;
      color: $white;
      line-height: 1.2;
    }
  }
}

.mobile-social {
  display: none;
}

.footer-copyright {
  margin-top: pxToRem(40);
  padding: pxToRem(35) 0;
  padding-bottom: 0;
  border-top: pxToRem(1) solid $white;
  justify-content: space-between;

  .list-service {
    display: flex;
    align-items: center;

    a {
      color: $white;
      font-size: pxToRem(20);
      font-style: normal;
      line-height: 1.2;
      margin-right: pxToRem(20);
      letter-spacing: normal;
      text-align: left;
    }
  }

  .copyright {
    color: $white;
    font-size: pxToRem(20);
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
}

@include breakpoint(sm992) {
  .desktop {
    display: none;
  }

  .mobile-social {
    display: block;
    margin-top: pxToVW(5);

    .address-title {
      font-size: pxToVW(15);
      margin-bottom: pxToVW(5);
      color: $white;
    }

    img {
      max-width: pxToVW(12);
      margin-right: 5px;
    }
  }

  .footer {
    padding: pxToVW(21);

    img {
      width: 100%;
    }

    .col-left {
      min-width: pxToVW(96);
      padding: 0;
      max-width: pxToVW(96);
    }

    .col-right {
      width: calc(100% - #{pxToVW(96)});
      min-width: calc(100% - #{pxToVW(96)});
      padding-right: 0;
    }

    .logo-footer {
      max-width: pxToVW(96);
      margin: 0;
    }

    .logo-copyright {
      margin: 0;
      text-align: left;
      max-width: pxToVW(71);
      margin-top: pxToVW(10);

      img {
        width: 100%;
      }
    }

    .info-company {
      h3 {
        font-size: pxToVW(13);
        margin-bottom: pxToVW(15);
      }

      .company-desc {
        margin-bottom: pxToVW(15);
        font-size: pxToVW(8);
      }

      .address {
        color: $white;
        margin-bottom: pxToVW(5);
      }

      .address-title {
        font-size: pxToVW(10);
      }

      .address-name {
        font-size: pxToVW(8);
      }

      .address-company {

        ul {
          li {
            width: 50%;

            img {
              margin-right: pxToVW(10);
            }
          }
        }
      }
    }


    .list-service {
      a {
        font-size: pxToVW(8);
      }
    }

    .copyright {
      margin-top: pxToVW(10);
      font-size: pxToVW(8);
    }
  }
}