.error-msg {
  margin-top: pxToRem(10);
  font-size: pxToRem(15);
  color: $red;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  margin-top: pxToVW(5);
}

.select {
  &.error-msg {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: pxToRem(5);
    width: 100%;
  }
}

@include breakpoint(sm992) {
  .error-msg {
    margin-top: pxToVW(10);
    font-size: pxToVW(12);
    margin-top: pxToVW(5);
  }

  .select {
    &.error-msg {
      margin-top: pxToVW(5);
    }
  }
}

.pt-7 {
  padding-top: pxToVW(50);
}