.checkbox {
  position: relative;

  * {
    cursor: pointer;
  }

  .checkbox__input {
    display: none;
  }

  .checkbox__label {


    &::before {
      height: 24px;
      width: 24px;
      border: 3px solid $bg-main;
      border-radius: 50%;
      top: 50%;
      position: absolute;
      content: "";
      display: inline-block;
      background-color: $white;
      @include transform-translateY(-50%);
      left: -24px;
    }

    &::after {
      position: absolute;
      display: inline-block;
      height: pxToVW(5);
      width: pxToVW(9);
      border-left: pxToVW(2) solid;
      border-bottom: pxToVW(2) solid;
      color: $white;
      transform: rotate(-45deg);
      content: none;
      left: pxToVW(2);
      top: pxToVW(4);
    }
  }

  &.checkbox--checked {
    .checkbox__label::after {
      content: "";
    }

    .checkbox__label::before {
      background-color: $bg-main;
      border: 0;
    }
  }
}