.input,
.react-datepicker-wrapper {
  &__error-msg {
    margin-top: pxToRem(10);
    font-size: pxToRem(15);
    color: $red;
  }

  &__label {
    font-size: pxToRem(15);
    font-weight: 500;
    line-height: 1.5;
    color: $color-black;
    margin-bottom: pxToRem(5);
    display: flex;
  }

  input {
    border: pxToRem(1) solid $color-border;
    height: pxToRem(44);
    display: block;
    font-size: pxToRem(16);
    padding: pxToRem(15);
    color: $color-black;
    width: 100%;
    letter-spacing: pxToRem(-0.25);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $regular;

    &::-webkit-input-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(14);
      letter-spacing: pxToRem(-0.25);
    }

    &::placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(14);
      letter-spacing: pxToRem(-0.25);
    }

    &::-moz-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(14);
      letter-spacing: pxToRem(-0.25);
    }

    &:-ms-input-placeholder {
      color: $color-placeholder;
      font-weight: $regular;
      font-size: pxToRem(14);
      letter-spacing: pxToRem(-0.25);
    }

    &.input-search {
      margin: auto;
      width: pxToRem(756);
      height: pxToRem(72);
      padding: pxToRem(15) pxToRem(25) pxToRem(13) pxToRem(156);

      color: $white;
      border-radius: pxToRem(15);
      border: solid pxToRem(2) rgba($white, 0.85);
      background-color: rgba($color-light0, 0.85);
      font-size: pxToRem(20);

      &::-webkit-input-placeholder {
        color: $color-light1;
        font-size: pxToRem(20);
        opacity: 0.58;
      }

      &::placeholder {
        color: $color-light1;
        font-size: pxToRem(20);
        opacity: 0.58;
      }

      &::-moz-placeholder {
        color: $color-light1;
        font-size: pxToRem(20);
        opacity: 0.58;
      }

      &:-ms-input-placeholder {
        color: $color-light1;
        font-size: pxToRem(20);
        opacity: 0.58;
      }
    }
  }

  &__box {
    position: relative;
    display: block;

    &.left {
      .input {
        padding-left: pxToRem(30);
      }
    }

    &__icon {
      position: absolute;
      @include transform-translateY(-50%);
      width: pxToRem(19);
      height: pxToRem(19);
      display: inline-block;
      z-index: 2;

      &__right {
        right: pxToRem(15);
        top: 50%;

        &:focus {
          outline: none;
        }

        img {
          width: pxToRem(19);
          height: pxToRem(19);
        }
      }

      &.left {
        left: pxToRem(15);
      }
    }

    &__custom {
      input {
        padding-right: pxToRem(35);
      }
    }
  }
}

.btn-wrap {
  position: relative;
  display: inline-block;

  .spinner-wrap {
    position: absolute;
    right: pxToRem(-20);
    top: 50%;
    @include transform-translateY(-50%);
  }
}

.input-change {
  border-radius: 5px;
}

input,
textarea {
  -webkit-appearance: none;
  font-family: $Roboto-Font;
  border-radius: pxToRem(10);

  &:focus {
    border: pxToRem(1) solid $bg-main;
    outline: none;
  }

  &.red {
    border-color: $red !important;
  }
}

@include breakpoint(sm992) {

  .input,
  .react-datepicker-wrapper {
    &__error-msg {
      margin-top: pxToVW(10);
      font-size: pxToVW(15);
    }

    &__label {
      font-size: pxToVW(13);
      margin-bottom: pxToVW(5);
    }

    input {
      border: pxToVW(1) solid $color-border;
      height: pxToVW(44);
      font-size: pxToVW(16);
      padding: pxToVW(10);
      letter-spacing: pxToVW(-0.25);

      &::-webkit-input-placeholder {
        font-size: pxToVW(14);
        letter-spacing: pxToVW(-0.25);
      }

      &::placeholder {
        font-size: pxToVW(14);
        letter-spacing: pxToVW(-0.25);
      }

      &::-moz-placeholder {
        font-size: pxToVW(14);
        letter-spacing: pxToVW(-0.25);
      }

      &:-ms-input-placeholder {
        font-size: pxToVW(14);
        letter-spacing: pxToVW(-0.25);
      }

      &.input-search {
        width: 70%;
        min-width: pxToVW(320);
        height: pxToVW(40);
        padding: pxToVW(5) pxToVW(15) pxToVW(5) pxToVW(80);
        border-radius: pxToVW(6);
        border: solid pxToVW(1) rgba($white, 0.85);
        font-size: pxToVW(16);

        &::-webkit-input-placeholder {
          font-size: pxToVW(16);
        }

        &::placeholder {
          font-size: pxToVW(16);
        }

        &::-moz-placeholder {
          font-size: pxToVW(16);
        }

        &:-ms-input-placeholder {
          font-size: pxToVW(16);
        }
      }
    }

    &__box {
      &.left {
        .input {
          padding-left: pxToVW(30);
        }
      }

      &__icon {
        width: pxToVW(19);
        height: pxToVW(19);

        &__right {
          right: pxToVW(15);

          img {
            width: pxToVW(19);
            height: pxToVW(19);
          }
        }

        &.left {
          left: pxToVW(15);
        }
      }

      &__custom {
        input {
          padding-right: pxToVW(35);
        }
      }
    }
  }

  .btn-wrap {
    .spinner-wrap {
      right: pxToVW(-20);
    }
  }

  input,
  textarea {
    border-radius: pxToVW(6);

    &:focus {
      border: pxToVW(1) solid $bg-main;
    }
  }
}

.input__box__custom {
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 10px;
    width: 30px;
    height: 30px;
    @include transform-translateY(-50%);
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    z-index: 1;
  }
}