.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: pxToRem(15);
  height: pxToRem(15);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: $bg-main;
  font-size: pxToRem(10);
  position: absolute;
  text-indent: -9999em;
  top: 50%;
  left: 50%;
  z-index: 99999;
  @include transform-translate(-50%, -50%);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: pxToRem(-34);
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: pxToRem(34);
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.icon-loading {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.5);

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px !important;
    height: 80px !important;

    @include breakpoint(sm992) {
      width: 60px !important;
      height: 60px !important;
    }
  }
}